import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Ionic2RatingModule } from 'ionic2-rating';

import { RatingPage } from './calendar/rating/rating.page';
import { SelectionPage } from './calendar/selection/selection.page';
import { RatingComponentForm } from './calendar/rating/component/rating-form.component';
import { AboutusPage } from './accounts/aboutus/aboutus.page';
import { AreaListsPage } from './pages/area-lists/area-lists.page';
import { MenuDetailPage } from './menu/menu-detail/menu-detail.page';
import { CustomizationModalPage } from './menu/customization-modal/customization-modal.page';
import { LimitedModalPage } from './accounts/limited-modal/limited-modal.page';
import { OtpVerificationPage } from './pages/otp-verification/otp-verification.page';
import { TermsAndConditionsPage } from './pages/terms-and-conditions/terms-and-conditions.page';
import { AutocompletePage } from './accounts/autocomplete/autocomplete.page';
import { AddAddressPage } from './accounts/add-address/add-address.page';
import { EditAddressPage } from './accounts/edit-address/edit-address.page';
import { SubscriptionDetailPage } from './accounts/subscription-list/subscription-detail/subscription-detail.page';
import { CitationModalPage } from './pages/citation-modal/citation-modal.page';
import { SignupModalPage } from './signup-modal/signup-modal.page';
import { SvgBackgroundComponent } from '../app/svg-background/svg-background.component';

import {
    pageTransition,
    customAlertEnter, customAlertLeave,
    customModalEnter, customModalLeave,
    customLoaderEnter, customLoaderLeave
} from './animations/customAnimation';

import { InterceptorService } from './services/interceptor.service';
import { ServiceWorkerModule } from '@angular/service-worker';
export const setTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
    declarations: [
        AppComponent,
        SvgBackgroundComponent,
        CitationModalPage,SignupModalPage,
        RatingPage, RatingComponentForm, SelectionPage,
        AboutusPage,
        AreaListsPage,
        MenuDetailPage,
        CustomizationModalPage,
        OtpVerificationPage,
        TermsAndConditionsPage,
        LimitedModalPage,
        AutocompletePage,
        AddAddressPage,
        EditAddressPage,
        SubscriptionDetailPage
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        Ionic2RatingModule,
        IonicStorageModule.forRoot(),
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: (setTranslateLoader),
                    deps: [HttpClient]
                }
            }
        ),
        IonicModule.forRoot(
            {
                mode: 'ios',
                animated: true,
                navAnimation: pageTransition,
                alertEnter: customAlertEnter,
                alertLeave: customAlertLeave,
                modalEnter: customModalEnter,
                modalLeave: customModalLeave,
                loadingEnter: customLoaderEnter,
                loadingLeave: customLoaderLeave
            }
        ),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        ScreenOrientation,
        Network, Device, Camera,
        CallNumber,
        AppVersion,
        InAppBrowser,
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
