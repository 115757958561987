import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

import { ConfigService } from 'src/app/provider/config.service';
import { ApiService } from 'src/app/provider/api.service';
import { endpoints } from 'src/app/constant/endpoints';
import { AuthService } from 'src/app/services/auth.service';
import { Events } from 'src/app/provider/events.service';

@Component({
    selector: 'app-limited-modal',
    templateUrl: './limited-modal.page.html',
    styleUrls: ['./limited-modal.page.scss'],
})
export class LimitedModalPage implements OnInit {
    @Input() title: any;
    @Input() reason: any;
    @Input() selected: any;
    @Input() unselected: any;
    settings: any = {}; customer: any
    maximumAllergySelection: number = 0;
    inventoriesArr = []; orginalList: any = [];
    selectedArr = [];
    loader:boolean = true;
    constructor(
        private events: Events,
        private platform: Platform,
        private modalCtrl: ModalController,
        private loadingCtrl: LoadingController,
        private toastCtrl: ToastController,
        private apiService: ApiService,
        private configService: ConfigService,
        private authService: AuthService,
        private translate: TranslateService,
    ) {
    }
    async ngOnInit() {
        this.customer = await this.authService.getAuthUser();
        const appPayload = await this.configService.getStorage('appPayload');
        this.maximumAllergySelection = appPayload?.configurations?.settings?.maximum_allergy_selection ? Number(appPayload?.configurations?.settings?.maximum_allergy_selection) : 0;
        console.log(this.maximumAllergySelection);
        console.log(this.title, this.reason);
        this.configService.getAppSettings()
            .then(async (settingData: any) => {
                this.settings = JSON.parse(settingData);
                //console.log(this.settings);
                this.translate.setDefaultLang(this.settings.appLanguage);
                this.translate.use(this.settings.appLanguage);
            }
            );
    }
    ionViewWillEnter() {
        this.customer = this.authService.getAuthUser();
        this.allInventories();
    }
    allInventories = () => {
        this.loader = true;
        console.log(endpoints[this.reason]);
        console.log(this.selected);
        console.log(this.unselected);
        this.apiService
            .get(endpoints[this.reason] + '?customer_id=' + this.customer.id)
            .subscribe((response) => {
                this.loader = false;
                console.log(response);
                if (response.success) {
                    this.inventoriesArr = response.data[this.reason];
                    this.inventoriesArr.forEach((item, index) => {
                        this.unselected && this.unselected.forEach(val => {
                            if (item.slug == val.slug) {
                                this.inventoriesArr.splice(index, 1);
                            }
                        });
                        this.selected && this.selected.map(selectedInv => {
                            if (item.slug == selectedInv.slug) {
                                item.checked = true;
                            }
                        });
                    });
                    this.orginalList = this.inventoriesArr;

                    /*this.inventoriesArr.map(item => {
                        this.selected.map(selectedInv => {
                            if (item.title == selectedInv.title) {
                                item.checked = true;
                            }
                        })
                    });*/
                } else if (response.code == 401) {
                    this.events.publish('user:login', { user: null, time: new Date() });
                }
            }
            );
    }
    selectInventory = (event, item) => {
        let _that = this;
        let selectedArr = _.filter(this.inventoriesArr, (val) => val.checked == true);
        if (event.target.checked) {
            if (selectedArr.length > Number(this.maximumAllergySelection - 1)) {
                _.forEach(_that.inventoriesArr, (item) => {
                    if (!item.checked) {
                        item.disabled = true;
                    }
                });
            }
        } else {
            if (Number(this.maximumAllergySelection) > selectedArr.length) {
                _.forEach(_that.inventoriesArr, (item) => {
                    if (!item.checked) {
                        item.disabled = false;
                    }
                });
            }
        }
    }
    getSelectedInventory = () => {
        let _that = this;
        let selectedNumber = 0;
        _.forEach(_that.inventoriesArr, (item) => {
            if (item.checked) {
                selectedNumber++;
            }
        });
        return selectedNumber;
    }
    getList = (ev: any) => {
        var val = ev.target.value;
        if (val && val.trim() != '') {
            this.inventoriesArr = this.inventoriesArr
                .filter((inventory) => {
                    //console.log(inventory);
                    if (this.platform.isRTL)
                        return (inventory.title.indexOf(val) > -1 || inventory.locale_title.toLowerCase().indexOf(val.toLowerCase()) > -1);
                    else
                        return (inventory.title.toLowerCase().indexOf(val.toLowerCase()) > -1);
                }
                );
        } else {
            this.clearLists();
        }
    }
    clearLists = () => {
        this.inventoriesArr = this.orginalList;
    }
    submitData = async () => {
        this.selectedArr = [];
        await this.inventoriesArr.map((inventory) => {
            if (inventory.checked) { this.selectedArr.push(inventory.id); }
        });
        let updateQuery = { 'customer_id': this.customer.id };
        if (this.reason == 'allergies') {
            Object.assign(updateQuery, { 'allergy_ids': this.selectedArr });
        } else {
            Object.assign(updateQuery, { 'dislike_ids': this.selectedArr });
        }
        this.apiService
            .post(endpoints[this.reason], updateQuery)
            .subscribe((response) => {
                console.log(response);
                this.toastMessage(response.message);
                if (response.success) {
                    this.modalCtrl.dismiss(response.message, 'close');
                } else if (response.code == 401) {
                    this.events.publish('user:login', { user: null, time: new Date() });
                }
            }
            );
    }
    toastMessage = async (msg) => {
        await this.toastCtrl.create({
            message: this.translate.instant(msg),
            duration: 4000,
            position: 'top',
            color: 'light'
        }).then((toastElem) => toastElem.present());
    }
    showLoading = async () => {
        this.loadingCtrl.create({
            message: this.translate.instant('please_wait'),
            cssClass: 'loader_class',
        }).then(a => {
            a.present().then(() => {
                console.log('presented');

            });
        });
    }
    hideLoading = async () => {
        setTimeout(async () => {
            try {
                this.loadingCtrl.dismiss();
            } catch (error) {
                console.log(error);
            }
        }, 1000);
    }
    closeModal = () => {
        this.modalCtrl.dismiss(null, 'cancel');
    }
}