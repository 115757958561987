import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface LoginForm {
    phone: string;
    password: string;
}

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    constructor(private http: HttpClient) { }
    setAuthToken(token) {
        window.localStorage.setItem('auth-token', token);
    }

    getAuthToken() {
        return localStorage.getItem('auth-token');
    }

    setAuthUser(user) {
        window.localStorage.setItem('auth-user', JSON.stringify(user));
    }

    getAuthUser() {
        return JSON.parse(localStorage.getItem('auth-user'));
    }
    getIPAddress() {
        return new Promise((resolve, reject) => {
            this.http.get("http://ip-api.com/json")
                .subscribe((res: any) => {
                    resolve(res);
                }
            );
        });
    }
}