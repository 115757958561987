import { AfterViewInit, Component, ElementRef, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
declare var google;
@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.page.html',
    styleUrls: ['./autocomplete.page.scss'],
})
export class AutocompletePage implements OnInit, AfterViewInit {
    @ViewChild('mapElement') mapNativeElement: ElementRef;
    @ViewChild('autoCompleteInput') inputNativeElement: any;
    directionForm: FormGroup;
    @Input() latitude:any = 29.3702067;
    @Input() longitude:any = 47.9717389;
    @Input() compound_code:any = '';
    @Input() global_code:any = '';
    mapStyle = [
        { featureType: "administrative", elementType: "labels", stylers: [{ visibility: "off" }] },
        { featureType: "landscape", elementType: "labels", stylers: [{ visibility: "off" }] },
        { featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] },
        { featureType: "transit", elementType: "all", stylers: [{ visibility: "off" }] },
        { featureType: "water", elementType: "labels", stylers: [{ visibility: "off" }] },
        { featureType: "road", elementType: "labels", stylers: [{ visibility: "off" }] }
    ];
    mapOptions = {
        zoom: 16,
        disableDefaultUI: true,
        draggable: true,
        center: new google.maps.LatLng(this.latitude, this.longitude),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        gestureHandling: 'greedy',
        mapTypeControl: false,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            position: google.maps.ControlPosition.LEFT_CENTER
        },
        panControl: false,
        panControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
        },
        zoomControl: false,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.LARGE,
            position: google.maps.ControlPosition.TOP_RIGHT
        },
        scrollwheel: true,
        scaleControl: false,
        scaleControlOptions: {
            position: google.maps.ControlPosition.TOP_LEFT
        },
        streetViewControl: false,
        streetViewControlOptions: {
            position: google.maps.ControlPosition.LEFT_TOP
        },
        styles: [this.mapStyle]
    };
    infowindow:any;
    infowindowContent:any
    map:any;marker:any;
    svgMarker = {
        path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        fillColor: "red",
        fillOpacity: 0.6,
        strokeWeight: 0,
        rotation: 0,
        scale: 3,
        anchor: new google.maps.Point(0, 20),
    }
    constructor(
        private fb: FormBuilder,
        private modalCtrl:ModalController
    ) {
        this.createDirectionForm();
    }
    ngOnInit() {
    }
    createDirectionForm() {
        this.directionForm = this.fb.group({
            placeName: [''],
        });
    }
    ngAfterViewInit(): void {
        this.map = new google.maps.Map(this.mapNativeElement.nativeElement, this.mapOptions);
        this.marker = new google.maps.Marker({
            map: this.map,
            anchorPoint: new google.maps.Point(0, -29)
        });
        this.infowindow = new google.maps.InfoWindow();
        this.infowindowContent = document.getElementById('infowindow-content');
        this.infowindow.setContent(this.infowindowContent);
        this.geocodePosition(new google.maps.LatLng(this.latitude,this.longitude), this.infowindowContent);
        console.log(typeof this.latitude, typeof this.longitude);

        this.autocompleteProcess();
    }
    autocompleteProcess = () => {
        const that = this;
        const autocomplete = new google.maps.places.Autocomplete(this.inputNativeElement.nativeElement as HTMLInputElement);
        google.maps.event.addListener(that.marker, 'dragend', function () {
            console.log(that.marker.getPosition());
            that.geocodePosition(that.marker.getPosition(), that.infowindowContent);
        }, false);
        this.map.addListener("click", (mapsMouseEvent) => {
            console.log(mapsMouseEvent.latLng);
            that.geocodePosition(mapsMouseEvent.latLng, that.infowindowContent);
        });
        autocomplete.addListener('place_changed', () => {
            that.infowindow.close();
            that.marker.setVisible(false);
            const place = autocomplete.getPlace();
            console.log(place);

            if (!place.geometry) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert('No details available for input: ' + place.name);
                return;
            }
            if (place.geometry.viewport) {
                that.map.fitBounds(place.geometry.viewport);
            } else {
                that.latitude = place.geometry.location.lat();
                that.longitude = place.geometry.location.lng();
                that.map.setCenter(place.geometry.location);
                that.map.setZoom(17);  // Why 17? Because it looks good.
            }
            let address = '';
            if (place.address_components) {
                address = [
                    (place.address_components[0] && place.address_components[0].short_name || ''),
                    (place.address_components[1] && place.address_components[1].short_name || ''),
                    (place.address_components[2] && place.address_components[2].short_name || '')
                ].join(' ');
            }
            that.infowindowContent.children['place-icon'].src = place.icon;
            that.geocodePosition(place.geometry.location, that.infowindowContent);
        });
    }
    geocodePosition = (position: any, infowindowContent: any) => {
        console.log(position.lat(), position.lng());
        const that = this;
        that.latitude = position.lat();
        that.longitude = position.lng();
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode
            ({
                latLng: position
            },
                function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        console.log(results);
                        let address = '';
                        const place = results[0];
                        if(place.plus_code){
                            console.log(place.plus_code.compound_code);
                            that.compound_code = place.plus_code.compound_code;
                            that.global_code = place.plus_code.global_code;
                        }else{
                            that.compound_code = '';
                            that.global_code = '';
                        }
                        if (place.address_components) {
                            address = [
                                (place.address_components[0] && place.address_components[0].short_name || ''),
                                (place.address_components[1] && place.address_components[1].short_name || ''),
                                (place.address_components[2] && place.address_components[2].short_name || '')
                            ].join(' ');
                        }
                        infowindowContent.children['place-name'].textContent = place.formatted_address;
                        infowindowContent.children['place-address'].textContent = address;

                        that.map.setCenter(place.geometry.location);
                        that.map.setZoom(17);

                        that.marker.setPosition(place.geometry.location);
                        that.marker.setIcon(that.svgMarker);
                        that.marker.setVisible(true);
                        that.marker.setDraggable(true);
                        that.infowindow.open(that.map, that.marker);
                    }else {
                        console.log(status);
                    }
                }
            );
    }
    closeModal = () => {
        this.modalCtrl.dismiss(null, 'cancel');
    }
    selectAddress = () => {
        this.modalCtrl.dismiss(
            {
                'latitude': this.latitude,
                'longitude': this.longitude,
                'compound_code': this.compound_code,
                'global_code': this.global_code
            },
            'close'
        );
    }
}
