import { Input,AfterViewInit, Renderer2, ElementRef  } from '@angular/core';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
@Component({
  selector: 'app-svg-background',
  templateUrl: './svg-background.component.html',
  styleUrls: ['./svg-background.component.scss']
})
export class SvgBackgroundComponent implements OnInit, OnDestroy { 
  private readonly STAR_COLOR = 'var(--ion-color-secondary)';
  private readonly STAR_SIZE = 0.5;
  private readonly STAR_MIN_SCALE = 0.1;
  private readonly OVERFLOW_THRESHOLD = 50;
  private readonly STAR_COUNT = (window.innerWidth + window.innerHeight) / 10;
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private scale = 1; // device pixel ratio
  private width: number;
  private height: number;
  private stars: { x: number; y: number; z: number }[] = [];
  private pointerX: number | null = null;
  private pointerY: number | null = null;
  private velocity = { x: 0, y: 0, tx: 0, ty: 0, z: 0.0005 };
  private touchInput = true;
  private animationFrameId: number | null = null;

  private isAnimating = true;
  private scrollInterval: any;
  private scrollSpeed: number = 5; // Adjust scrolling speed as needed
  private directionX: number = -1; // 1 for right, -1 for left
  private directionY: number = -1; // 1 for down, -1 for up

  constructor(
    private renderer: Renderer2,
     private el: ElementRef
    ) {}
    
  //   ngOnInit() {
  //     console.log('SvgBackgroundComponent initialized');
  //   }
  // @Input() primaryColor: string = 'var(--p2)';
  // @Input() secondaryColor: string = 'var(--s2)';

  // ngAfterViewInit(): void {
  //   // this.startAutoScroll();
  // }

  // ngOnDestroy(): void {
  //   // if (this.scrollInterval) {
  //   //   clearInterval(this.scrollInterval);
  //   // }
  // }


  


  ngOnInit(): void {
    this.canvas = document.querySelector('canvas')!;
    this.context = this.canvas.getContext('2d')!;
    this.generate();
    this.resize();
    this.step();
    window.addEventListener('resize', this.resize.bind(this));
  }

  ngOnDestroy(): void {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    window.removeEventListener('resize', this.resize.bind(this));
  }

  private generate(): void {
    for (let i = 0; i < this.STAR_COUNT; i++) {
      this.stars.push({
        x: 0,
        y: 0,
        z: this.STAR_MIN_SCALE + Math.random() * (1 - this.STAR_MIN_SCALE)
      });
    }
  }

  private placeStar(star: { x: number; y: number; z: number }): void {
    star.x = Math.random() * this.width;
    star.y = Math.random() * this.height;
  }

  private recycleStar(star: { x: number; y: number; z: number }): void {
    let direction = 'z';
    const vx = Math.abs(this.velocity.x);
    const vy = Math.abs(this.velocity.y);
    if (vx > 1 || vy > 1) {
      const axis = vx > vy ? (Math.random() < vx / (vx + vy) ? 'h' : 'v') : (Math.random() < vy / (vx + vy) ? 'v' : 'h');
      direction = axis === 'h' ? (this.velocity.x > 0 ? 'l' : 'r') : (this.velocity.y > 0 ? 't' : 'b');
    }
    star.z = this.STAR_MIN_SCALE + Math.random() * (1 - this.STAR_MIN_SCALE);
    if (direction === 'z') {
      star.z = 0.1;
      star.x = Math.random() * this.width;
      star.y = Math.random() * this.height;
    } else if (direction === 'l') {
      star.x = -this.OVERFLOW_THRESHOLD;
      star.y = this.height * Math.random();
    } else if (direction === 'r') {
      star.x = this.width + this.OVERFLOW_THRESHOLD;
      star.y = this.height * Math.random();
    } else if (direction === 't') {
      star.x = this.width * Math.random();
      star.y = -this.OVERFLOW_THRESHOLD;
    } else if (direction === 'b') {
      star.x = this.width * Math.random();
      star.y = this.height + this.OVERFLOW_THRESHOLD;
    }
  }

  private resize(): void {
    this.scale = window.devicePixelRatio || 1;
    this.width = window.innerWidth * this.scale;
    this.height = window.innerHeight * this.scale;
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.stars.forEach(star => this.placeStar(star));
  }

  private step(): void {
    this.context.clearRect(0, 0, this.width, this.height);
    this.update();
    this.render();
    this.animationFrameId = requestAnimationFrame(this.step.bind(this));
  }

  private update(): void {
    this.velocity.tx *= 0.96;
    this.velocity.ty *= 0.96;
    this.velocity.x += (this.velocity.tx - this.velocity.x) * 0.8;
    this.velocity.y += (this.velocity.ty - this.velocity.y) * 0.8;
    this.stars.forEach(star => {
      star.x += this.velocity.x * star.z;
      star.y += this.velocity.y * star.z;
      star.x += (star.x - this.width / 2) * this.velocity.z * star.z;
      star.y += (star.y - this.height / 2) * this.velocity.z * star.z;
      star.z += this.velocity.z;
      if (star.x < -this.OVERFLOW_THRESHOLD || star.x > this.width + this.OVERFLOW_THRESHOLD || star.y < -this.OVERFLOW_THRESHOLD || star.y > this.height + this.OVERFLOW_THRESHOLD) {
        this.recycleStar(star);
      }
    });
  }

  private render(): void {
    this.stars.forEach(star => {
      this.context.beginPath();
      this.context.lineCap = 'round';
      this.context.lineWidth = this.STAR_SIZE * star.z * this.scale;
      this.context.globalAlpha = 0.5 + 0.5 * Math.random();
      this.context.strokeStyle = this.STAR_COLOR;
      this.context.moveTo(star.x, star.y);
      let tailX = this.velocity.x * 2, tailY = this.velocity.y * 2;
      if (Math.abs(tailX) < 0.1) tailX = 0.5;
      if (Math.abs(tailY) < 0.1) tailY = 0.5;
      this.context.lineTo(star.x + tailX, star.y + tailY);
      this.context.stroke();
    });
  }

  private movePointer(x: number, y: number): void {
    if (typeof this.pointerX === 'number' && typeof this.pointerY === 'number') {
      const ox = x - this.pointerX!;
      const oy = y - this.pointerY!;
      this.velocity.tx += (ox / 8 * this.scale) * (this.touchInput ? 1 : -1);
      this.velocity.ty += (oy / 8 * this.scale) * (this.touchInput ? 1 : -1);
    }
    this.pointerX = x;
    this.pointerY = y;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    this.touchInput = true;
    this.movePointer(event.clientX, event.clientY);
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent): void {
    this.touchInput = true;
    this.movePointer(event.touches[0].clientX, event.touches[0].clientY);
    event.preventDefault();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.pointerX = null;
    this.pointerY = null;
  }

}
