
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

import { ApiService } from 'src/app/provider/api.service';
import { ConfigService } from 'src/app/provider/config.service';
import { endpoints } from 'src/app/constant/endpoints';
import { AuthService } from 'src/app/services/auth.service';
import { Events } from 'src/app/provider/events.service';

@Component({
    selector: 'app-otp-verification',
    templateUrl: './otp-verification.page.html',
    styleUrls: ['./otp-verification.page.scss'],
})
export class OtpVerificationPage implements OnInit {
    @Input() phone: any;
    @Input() country_iso_code: any;
    @Input() otp_sent: boolean;
    @Input() email: boolean;
    settings: any = {};

    timeCountDown: any = '';
    maxTime: number = 36;
    resentActive: boolean = false;
    otpVerification: string;
    page: any;
    userPhoneCode: '+965';
    //country_iso_code: string = 'KW';
    otpForm: FormGroup;
    redirectTo: string = 'signin';
    historyRoute: string = 'signin';
    customer: any;
    otpStatus: any = '';
    constructor(
        private events: Events,
        private ngZone: NgZone,
        private router: Router,
        private navCtrl: NavController,
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private toastCtrl: ToastController,
        private loadingCtrl: LoadingController,
        private translate: TranslateService,
        private apiService: ApiService,
        private authService: AuthService,
        private configService: ConfigService,

    ) {
        this.customer = this.authService.getAuthUser();
        this.otpForm = this.formBuilder.group({
            number1: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(1),
                Validators.pattern('^[0-9]$'),
            ])),
            number2: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(1),
                Validators.pattern('^[0-9]$'),
            ])),
            number3: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(1),
                Validators.pattern('^[0-9]$'),
            ])),
            number4: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(1),
                Validators.pattern('^[0-9]$'),
            ])),
            number5: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(1),
                Validators.pattern('^[0-9]$'),
            ])),
            number6: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(1),
                Validators.pattern('^[0-9]$'),
            ])),
        });
    }
    async ngOnInit() {
        const storeData = await this.configService.getStorage('appPayload');
        this.userPhoneCode = storeData.dialing_code ? storeData.dialing_code : this.userPhoneCode;
        //if (!this.otp_sent) { this.getOTP(); }
        this.startTimer();
        this.configService.getAppSettings()
            .then((settingData: any) => {
                this.settings = JSON.parse(settingData);
                //console.log(this.settings);
                this.translate.setDefaultLang(this.settings.appLanguage);
                this.translate.use(this.settings.appLanguage);
            }
            );
    }
    getOTP = () => {
        this.apiService
            .get(endpoints.sendOTP + '?customer_id=' + this.customer.id)
            .pipe(first())
            .subscribe((response) => {
                console.log(response);
                if (response.message || response.success) {
                    this.toastMesssage(response.message);
                } else if (response.code == 401) {
                    this.events.publish('user:login', { user: null, time: new Date() });
                }
            }, (err) => {
                this.toastMesssage(err.error.message);
            });
    }
    verifyOTP = () => {
        if (this.otpForm.valid) {
            this.showLoading();
            this.otpStatus = '';
            this.apiService
                .post(endpoints.verifyOTP,
                    {
                        "phone": this.phone,
                        "country_iso_code": this.country_iso_code,
                        "customer_id": this.customer.id,
                        "otp": this.otpForm.value.number1 + this.otpForm.value.number2 + this.otpForm.value.number3 + this.otpForm.value.number4 + this.otpForm.value.number5 + this.otpForm.value.number6
                    }
                )
                .pipe(first())
                .subscribe((response) => {
                    this.hideLoading();
                    if (response.success) {
                        this.otpStatus = 'true';
                        this.toastMesssage(response.message);
                        this.modalCtrl.dismiss(response.success, 'close');
                    } else if (response.code == 401) {
                        this.events.publish('user:login', { user: null, time: new Date() });
                    } else {
                        this.otpStatus = 'false';
                    }
                }, (err) => {
                    console.log(err);
                    this.hideLoading();
                    this.toastMesssage(err.error.otp[0]);
                });
        }
    }
    resendOTP = () => {
        this.resentActive = false;
        this.getOTP();
        this.startTimer();
    }

    moveFocus = (nextElement, e, prevElement) => {
        if (e.key === 'Backspace') {
            if (nextElement.value === '') {
                prevElement.focus();
            }
        } else if (e.key !== 'Backspace' && e.key !== ' ') {
            nextElement.focus();
        }
    }
    startTimer = () => {
        this.maxTime = 36;
        this.timeCountDown = '';
        this.timeCountDown = setInterval(() => {
            if (this.maxTime === 0) {
                clearInterval(this.timeCountDown);
                this.resentActive = true;
                return false;
            } else {
                this.maxTime -= 1;
                return null;
            }
        }, 1000);
    }
    closeModal = () => {
        this.modalCtrl.dismiss(false, 'cancel');
    }
    showLoading = async () => {
        this.loadingCtrl.create({
            message: this.translate.instant('please_wait'),
            cssClass: 'loader_class',
        }).then(a => {
            a.present().then(() => {
                console.log('presented');

            });
        });
    }

    hideLoading = async () => {
        setTimeout(async () => {
            try {
                this.loadingCtrl.dismiss();
            } catch (error) {
                console.log(error);
            }
        }, 1000);
    }
    toastMesssage = async (msg: string) => {
        await this.toastCtrl.create({
            message: this.translate.instant(msg),
            duration: 4000,
            position: 'top',
            color: 'light'
        }).then(toast => toast.present());
    }
}

