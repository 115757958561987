

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import * as moment from 'moment';
import { AuthService } from '../../app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private cachedData: { [date: string]: any } = {};
  private currentMonth: number;
  private daysArray: string[] = [];
  customer: any;

  constructor(private http: HttpClient, private storage: Storage,private authService: AuthService) {
    this.initializeStorage();
  }

  async initializeStorage() {
    await this.storage.create();
    const storedData = await this.storage.get('cachedData');
    if (storedData) {
      this.cachedData = storedData;
    }
  }

  async updateDayData(date: string) {
    try {
      // Make the API call to fetch data for the given date
      this.customer = this.authService.getAuthUser();
      const data: any = await this.http
        .get(`${environment.API_URL}menu_builders?date=${date}&is_double_box=false&customer_id=${this.customer.id}`)
        .toPromise();
      const main = data.data.menu_builders;
  
      // Update the cached data with the new data for the given date
      this.cachedData[date] = main;
  
      // Update the cache in local storage
      await this.storage.set('cachedData', this.cachedData);
  
      console.log(`Data for ${date} updated from API and cache refreshed`);
      return main;
    } catch (error) {
      console.error(`Error updating data for ${date}:`, error);
      throw error;
    }
  }

  async fetchDataForDate(date: string) {
    // Check if the data is already cached
    if (this.cachedData[date]) {
      // console.log(`Data for ${date} loaded from cache`);
      return this.cachedData[date];
    }

    // If not cached, make the API call
    try {
      this.customer = this.authService.getAuthUser();
      const data: any = await this.http
        .get(`${environment.API_URL}menu_builders?date=${date}&is_double_box=false&customer_id=${this.customer.id}`)
        .toPromise();
    const main = data.data.menu_builders;
  // Function to call the API for a specific date


      // Store the data in the cache
      this.cachedData[date] = main;
      
      // Update the cache in local storage
      await this.storage.set('cachedData', this.cachedData);

      console.log(`Data for ${date} loaded from API`);
      return main;
    } catch (error) {
      console.error(`Error loading data for ${date}:`, error);
      throw error;
    }
  }

  async loadData(date: string) {
    // Retrieve the stored month and days chunks
    const storedMonth = await this.storage.get('currentMonth');
    const storedDaysChunks: string[][] = await this.storage.get('daysChunks');
  
    // If the month has changed or the days chunks are not present, recalculate and store days in chunks
    if (storedMonth !== moment(date).month() || !storedDaysChunks) {
      await this.calculateAndStoreDays(date);
    }
  
    // Load the updated daysChunks after recalculation if necessary
    const daysChunks: string[][] = await this.storage.get('daysChunks');
  
    // Find the chunk that contains the input date
    const inputDateMoment = moment(date);
    let selectedChunk: string[] | undefined;
    daysChunks.forEach(chunk => {
      if (chunk.includes(inputDateMoment.format('YYYY-MM-DD'))) {
        selectedChunk = chunk;
      }
    });
  
    if (!selectedChunk) {
      console.error('Input date not found in any chunk.');
      return null;
    }
  
    // Filter out days that are before the input date and keep only the days after
    const remainingDays = selectedChunk.filter(d => moment(d).isAfter(inputDateMoment));
  
    // Load and return the data for the input date first
    const inputData = await this.fetchDataForDate(date);
  
    // Perform background API calls for the days in the current chunk that are after the input date
    if (remainingDays.length > 0) {
      remainingDays.forEach(async (day) => {
        try {
          const data = await this.fetchDataForDate(day);
          // console.log(`Data for ${day} loaded in the background`, data);
        } catch (error) {
          console.error(`Error loading data for ${day}:`, error);
        }
      });
  
      // If remaining days are less than 3, load the next chunk
      if (remainingDays.length < 3) {
        const currentChunkIndex = daysChunks.indexOf(selectedChunk);
        const nextChunk = daysChunks[currentChunkIndex + 1];
  
        if (nextChunk) {
          nextChunk.forEach(async (day) => {
            try {
              const data = await this.fetchDataForDate(day);
              // console.log(`Data for ${day} loaded from the next chunk in the background`, data);
            } catch (error) {
              console.error(`Error loading data for ${day} from the next chunk:`, error);
            }
          });
        } else {
          console.warn('No more chunks available to load.');
        }
      }
    } else {
      console.warn('No remaining days to load data for in the current chunk.');
    }
  
    return inputData; // Return only the input date's data for immediate use
  }


  async calculateAndStoreDays(dateString: string) {
    const date = moment(dateString);
    if (!date.isValid()) {
      console.error('Invalid date provided:', dateString);
      return;
    }
  
    this.currentMonth = date.month();
    const daysInMonth = date.daysInMonth();
    const currentDay = date.date();
  
    // Array to store chunks of 10 days
    const chunks: string[][] = [];
    let chunk: string[] = [];
  
    // Iterate over the days of the month
    for (let day = currentDay; day <= daysInMonth; day++) {
      chunk.push(date.clone().date(day).format('YYYY-MM-DD'));
  
      // If the chunk has 10 days or if it's the last day of the month, push the chunk to chunks
      if (chunk.length === 10 || day === daysInMonth) {
        chunks.push(chunk);
        chunk = []; // Reset the chunk
      }
    }

    
  
    // Save the chunks and the current month in storage
    await this.storage.set('daysChunks', chunks);
    await this.storage.set('currentMonth', this.currentMonth);
  }
  async clearCacheAndReload(date: string) {
    try {
      // Clear the cached data
      await this.storage.remove('currentMonth');
      await this.storage.remove('daysChunks');
      console.log('Cache cleared.');
  
      // Recalculate and store fresh data for the new date
      await this.calculateAndStoreDays(date);
      console.log('Fresh data recalculated and stored.');
  
      // Reload data for the given date
      const freshData = await this.loadData(date);
      console.log('Fresh data loaded for the input date:', freshData);
  
      return freshData; // Return the reloaded data
    } catch (error) {
      console.error('Error clearing cache and reloading data:', error);
      throw error; // Re-throw the error if needed
    }
  }
  
}