
import { CLIENTS, INDEX } from "../../applications";

export const environment = {
    production: false,
    APP_VERSION: '1.0.0',
    APP_NAME: CLIENTS[INDEX - 1].appName,
    IDENTIFIER: CLIENTS[INDEX - 1].identifier,
    LOGIN_PAGE: 'signin',
    API_URL: "https://console.dietmaster.fit/api/customer/",
    // API_URL: "http://127.0.0.1:8000/api/customer/",
    TABBY_PUBLIC_KEY: "pk_test_0ac0699f-11ef-4183-99f0-063a5bb28084",
    TABBY_MERCHANT_CODE: "EatSmartkwt"
};
