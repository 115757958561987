import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'tabs',
		pathMatch: 'full'
	},
	{
		path: 'onboarding',
		loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingPageModule)
	},
	{
		path: 'signin',
		loadChildren: () => import('./signin/signin.module').then(m => m.SigninPageModule)
	},
	{
		path: 'signup',
		loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
	},
	{
		path: 'reset-password',
		loadChildren: () => import('./accounts/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
	},
	{
		path: 'tabs',
		canActivate: [AuthGuard],
		loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
	},
	{
		path: 'profile',
		canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/profile/profile.module').then(m => m.ProfilePageModule)
	},
	{
		path: 'change-password',
		canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
	},
	{
		path: 'address-lists',
		canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/address-lists/address-lists.module').then(m => m.AddressListsPageModule)
	},
	{
		path: 'my-subscriptions',
		canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/my-subscriptions/my-subscriptions.module').then(m => m.MySubscriptionsPageModule)
	},
	{
        path: 'upgrade-subscription',
        canActivate: [AuthGuard],
        loadChildren: () => import('./accounts/upgrade-subscription/upgrade-subscription.module').then(m => m.UpgradeSubscriptionPageModule)
    },
	{
		path: 'subscription-list',
		//canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/subscription-list/subscription-list.module').then(m => m.SubscriptionListPageModule)
	},
	{
		path: 'checkout',
		canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/checkout/checkout.module').then(m => m.CheckoutPageModule)
	},
	{
        path: 'checkout-pending',
		canActivate: [AuthGuard],
        loadChildren: () => import('./accounts/checkout-pending/checkout-pending.module').then(m => m.CheckoutPendingPageModule)
    },
	{
		path: 'consultation',
		canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/consultation/consultation.module').then(m => m.ConsultationPageModule)
	},
	{
		path: 'new-consultation',
		//canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/consultation/new-consultation/new-consultation.module').then(m => m.NewConsultationPageModule)
	},
	{
		path: 'how-it-works',
		canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/how-it-works/how-it-works.module').then(m => m.HowItWorksPageModule)
	},
	{
		path: 'settings',
		canActivate: [AuthGuard],
		loadChildren: () => import('./accounts/settings/settings.module').then(m => m.SettingsPageModule)
	},
	{
		path: 'menu',
		canActivate: [AuthGuard],
		loadChildren: () => import('./menu/menu.module').then(m => m.MenuPageModule)
	},
	{
		path: 'online-order',
		loadChildren: () => import('./order/order.module').then(m => m.OrderPageModule)
	},
	{
		path: 'customer-details',
		loadChildren: () => import('./order/customer-details/customer-details.module').then(m => m.CustomerDetailsPageModule)
	},
	{
        path: 'order-checkout',
        loadChildren: () => import('./order/order-checkout/order-checkout.module').then(m => m.OrderCheckoutPageModule)
    },
	{
        path: 'order-confirm/:id',
        loadChildren: () => import('./order/order-confirmed/order-confirmed.module').then(m => m.OrderConfirmedPageModule)
    },
	{
        path: 'order-failed/:id',
        loadChildren: () => import('./order/order-failed/order-failed.module').then(m => m.OrderFailedPageModule)
    },
	{
        path: 'order-history',
        loadChildren: () => import('./order/order-history/order-history.module').then(m => m.OrderHistoryPageModule)
    },
	{
        path: 'order-details/:id',
        loadChildren: () => import('./order/order-details/order-details.module').then(m => m.OrderDetailsPageModule)
    },
	/*{
		path: 'add-address',
		loadChildren: () => import('./accounts/add-address/add-address.module').then(m => m.AddAddressPageModule)
	},
	{
		path: 'otp-verification',
		loadChildren: () => import('./pages/otp-verification/otp-verification.module').then(m => m.OtpVerificationPageModule)
	},*/
	{
		path: 'home-tour',
		loadChildren: () => import('./tour-guide/home-tour/home-tour.module').then(m => m.HomeTourPageModule)
	},
	{
		path: 'address-tour',
		loadChildren: () => import('./tour-guide/address-tour/address-tour.module').then(m => m.AddressTourPageModule)
	},
	{
		path: 'subscription-tour',
		loadChildren: () => import('./tour-guide/subscription-tour/subscription-tour.module').then(m => m.SubscriptionTourPageModule)
	},
	{
		path: 'calendar-tour',
		loadChildren: () => import('./tour-guide/calendar-tour/calendar-tour.module').then(m => m.CalendarTourPageModule)
	},
	{
		path: 'mysubscription-tour',
		loadChildren: () => import('./tour-guide/mysubscription-tour/mysubscription-tour.module').then(m => m.MySubscriptionTourPageModule)
	},
	{
		path: 'checkout-tour',
		loadChildren: () => import('./tour-guide/checkout-tour/checkout-tour.module').then(m => m.CheckoutTourPageModule)
	},
	{
		path: 'menu-list',
		loadChildren: () => import('./menu-list/menu-list.module').then(m => m.MenuListPageModule)
	},
];
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
