import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { endpoints } from 'src/app/constant/endpoints';
import { ApiService } from 'src/app/provider/api.service';
import { ConfigService } from 'src/app/provider/config.service';
import _ from 'lodash';


@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.page.html',
    styleUrls: ['./terms-and-conditions.page.scss'],
})
export class TermsAndConditionsPage implements OnInit {
    settings: any = {};
    content: any;
    constructor(
        public modalCtrl: ModalController,
        private apiService: ApiService,
        private configService: ConfigService,
    ) { }

    ngOnInit() {
        this.apiService
            .get(endpoints.cms)
            .subscribe((res) => {
                //console.log(res);
                if (this.settings.appLanguage == 'ar') {
                    this.content = _.filter(res.data.pages, (val) => val.title == "Terms & Conditions")[0].a_content;
                }
                else {
                    this.content = _.filter(res.data.pages, (val) => val.title == "Terms & Conditions")[0].content;
                }
                //console.log(this.content);
            });
    }
    ionViewWillEnter() {
        this.configService.getAppSettings()
            .then((settingData: any) => {
                this.settings = JSON.parse(settingData);
                //console.log(this.settings);
            }
            );
    }
    acceptModal = () => {
        this.modalCtrl.dismiss(true, 'close');
    }
    closeModal = () => {
        this.modalCtrl.dismiss(false, 'cancel');
    }
}
