import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

import { endpoints } from 'src/app/constant/endpoints';
import { AccountService } from 'src/app/provider/account.service';
import { ApiService } from 'src/app/provider/api.service';
import { ConfigService } from 'src/app/provider/config.service';
import { Events } from 'src/app/provider/events.service';
import { AuthService } from 'src/app/services/auth.service';

import { AutocompletePage } from '../autocomplete/autocomplete.page';
import { AreaListsPage } from 'src/app/pages/area-lists/area-lists.page';

@Component({
    selector: 'app-edit-address',
    templateUrl: './edit-address.page.html',
    styleUrls: ['./edit-address.page.scss'],
})
export class EditAddressPage implements OnInit {
    settings: any = {}; customer: any;
    storeData: any = {}; address_fields: any[] = [];
    addUpdateForm: FormGroup;
    areaSlots: any = [];
    areasOrRegion: any = [];
    areasOrRegionId: any = null;
    areasOrRegionName: string;
    areaSlotId: any = null;
    governorateId: any = null;
    addid: string; address: any;
    historyRoute = 'address-lists';

    address_name: string = '';
    phone: number = null;
    block: any = '';
    street: any = '';
    house_name: any = '';
    avenue: any = '';
    floor: any = '';
    flat: any = '';
    landmark: any = '';
    latitude: any = 0;
    longitude: any = 0;
    compound_code: any = '';
    global_code: any = '';
    PACI_number: any = '';
    location: any = '';
    driver_note: any = '';
    zip_code: any = '';
    notes: any = '';
    constructor(
        private events: Events,
        private formBuilder: FormBuilder,
        private toastCtrl: ToastController,
        private modalCtrl: ModalController,
        private translate: TranslateService,
        private navParams: NavParams,
        private configService: ConfigService,
        private apiService: ApiService,
        private authService: AuthService,
        private accountService: AccountService
    ) {
        this.customer = this.authService.getAuthUser();
        this.addid = this.navParams.get('addid');
        this.apiService
            .get(endpoints.areas)
            .pipe(first())
            .subscribe((response) => {
                if (response.success) {
                    this.areasOrRegion = response.data.areas;
                    console.log(this.areasOrRegion);
                } else if (response.code == 401) {
                    this.events.publish('user:login', { user: null, time: new Date() });
                }
            });
        this.addUpdateForm = this.formBuilder.group({
            customer_id: new FormControl(this.customer.id),
            areasOrRegionId: new FormControl("", Validators.compose([
                Validators.required,
            ])),
            areaSlotId: new FormControl("", Validators.compose([
                Validators.required,
            ])),
            is_default: new FormControl(false, Validators.compose([
                Validators.required,
            ])),
            /*address_name: new FormControl("", Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(20),
            ])),
            phone: new FormControl("", Validators.compose([
                Validators.required,
                Validators.minLength(8)
            ])),
            house_name: new FormControl("", Validators.compose([
                Validators.required,
            ])),
            block: new FormControl("", Validators.compose([
                Validators.required,
            ])),
            street: new FormControl("", Validators.compose([
                Validators.required,
            ])),
            avenue: new FormControl("", Validators.compose([
                Validators.required,
            ])),
            floor: new FormControl("", Validators.compose([
                Validators.required,
            ])),
            flat: new FormControl("", Validators.compose([
                Validators.required,
            ])),
            landmark: new FormControl("", Validators.compose([
                Validators.required,
            ])),
            latitude: new FormControl(this.latitude),
            longitude: new FormControl(this.longitude),
            */
        });
    }
    async ngOnInit() {
        this.apiService
            .get(endpoints.addresses + '/' + this.addid + '?customer_id=' + this.customer.id)
            .pipe(first())
            .subscribe((response) => {
                if (response.success) {
                    this.address = response.data.address;
                    console.log(this.address);

                    this.latitude = this.address.latitude ? this.address.latitude : this.latitude;
                    this.longitude = this.address.longitude ? this.address.longitude : this.longitude;
                    this.areaSlotId = this.address.slot_id;
                    this.areasOrRegionId = this.address.area_id;
                    this.areasOrRegionName = this.address.area;
                    console.log(this.areasOrRegionId);

                    this.onAreaChange(this.address.area_id);
                    this.address_name = this.address.name;
                    this.phone = this.address.phone;
                    this.house_name = this.address.house_name;
                    this.block = this.address.block;
                    this.street = this.address.street;
                    this.avenue = this.address.avenue;
                    this.floor = this.address.floor;
                    this.flat = this.address.flat;
                    this.landmark = this.address.landmark;
                    this.PACI_number = this.address.PACI_number;
                    this.zip_code = this.address.zip_code;
                    this.location = this.address.location;
                    this.driver_note = this.address.driver_note;
                    this.notes = this.address.notes;
                    /*this.addUpdateForm.controls['name'].patchValue(this.address.name);
                    this.addUpdateForm.controls['phone'].patchValue(this.address.phone);
                    this.addUpdateForm.controls['house_name'].patchValue(this.address.house_name);
                    this.addUpdateForm.controls['block'].patchValue(this.address.block);
                    this.addUpdateForm.controls['street'].patchValue(this.address.street);
                    this.addUpdateForm.controls['avenue'].patchValue(this.address.avenue);
                    this.addUpdateForm.controls['floor'].patchValue(this.address.floor);
                    this.addUpdateForm.controls['flat'].patchValue(this.address.flat);
                    this.addUpdateForm.controls['landmark'].patchValue(this.address.landmark);*/
                    this.addUpdateForm.controls['is_default'].patchValue(this.address.is_default);
                } else if (response.code == 401) {
                    this.events.publish('user:login', { user: null, time: new Date() });
                }
            });
        this.storeData = await this.configService.getStorage('appPayload');
        this.address_fields = this.storeData.address_fields;
        console.log(this.address_fields);
        this.configService.getAppSettings()
            .then((settingData: any) => {
                this.settings = JSON.parse(settingData);
                //console.log(this.settings);
                this.translate.setDefaultLang(this.settings.appLanguage);
                this.translate.use(this.settings.appLanguage);
            }
            );
    }
    onSubmitAddress = () => {
        console.log(this.addUpdateForm.value);
        let addUpdateFormValue = this.addUpdateForm.value;
        Object.assign(addUpdateFormValue, {
            "area_id": this.areasOrRegionId ? this.areasOrRegionId : null,
            "slot_id": this.areaSlotId,
            "governorate_id": this.governorateId,
            "latitude": this.latitude,
            "longitude": this.longitude,
            "compound_code": this.compound_code,
            "global_code": this.global_code
        });
        if (this.address_fields.indexOf('address_name') > -1) { Object.assign(addUpdateFormValue, { "name": this.address_name, }); }
        if (this.address_fields.indexOf('phone') > -1) { Object.assign(addUpdateFormValue, { "phone": this.phone, }); }
        if (this.address_fields.indexOf('PACI_number') > -1) { Object.assign(addUpdateFormValue, { "PACI_number": this.PACI_number, }); }
        if (this.address_fields.indexOf('block') > -1) { Object.assign(addUpdateFormValue, { "block": this.block, }); }
        if (this.address_fields.indexOf('street') > -1) { Object.assign(addUpdateFormValue, { "street": this.street, }); }
        if (this.address_fields.indexOf('house_name') > -1) { Object.assign(addUpdateFormValue, { "house_name": this.house_name, }); }
        if (this.address_fields.indexOf('avenue') > -1) { Object.assign(addUpdateFormValue, { "avenue": this.avenue, }); }
        if (this.address_fields.indexOf('floor') > -1) { Object.assign(addUpdateFormValue, { "floor": this.floor, }); }
        if (this.address_fields.indexOf('flat') > -1) { Object.assign(addUpdateFormValue, { "flat": this.flat, }); }
        if (this.address_fields.indexOf('landmark') > -1) { Object.assign(addUpdateFormValue, { "landmark": this.landmark, }); }
        if (this.address_fields.indexOf('zip_code') > -1) { Object.assign(addUpdateFormValue, { "zip_code": this.zip_code, }); }
        if (this.address_fields.indexOf('notes') > -1) { Object.assign(addUpdateFormValue, { "notes": this.notes, }); }
        if (this.address_fields.indexOf('driver_note') > -1) { Object.assign(addUpdateFormValue, { "driver_note": this.driver_note, }); }
        console.log(addUpdateFormValue);
        //delete addUpdateFormValue.areasOrRegionId;
        delete addUpdateFormValue.areaSlotId;
        delete addUpdateFormValue.areasOrRegionId;
        //return;
        this.apiService
            .put(endpoints.addresses + '/' + this.addid, addUpdateFormValue)
            .pipe(first())
            .subscribe((response) => {
                console.log(response)
                console.log(response.message);
                if (response.success) {
                    this.toastMessage(response.message);
                    this.modalCtrl.dismiss(true, 'close');
                } else if (response.code == 401) {
                    this.events.publish('user:login', { user: null, time: new Date() });
                } else {
                    this.toastMessage(response.message);
                }
            }, (err) => {
                Object.keys(err.error).forEach((key) => {
                    let errorArray = err.error[key];
                    Object.keys(errorArray).forEach((index) => {
                        this.toastMessage(errorArray[index]);
                    });
                });
            });

    }
    openAreaModal = async () => {
        const areaModal = await this.modalCtrl.create({
            component: AreaListsPage,
            componentProps: {
                'citylist': this.areasOrRegion,
                'orginallist': this.areasOrRegion
            }
        });
        areaModal.onDidDismiss()
            .then((data) => {
                console.log(data);
                if (data.data) {
                    console.log(data.data);
                    this.areasOrRegionId = data.data.id;
                    this.areasOrRegionName = this.settings.appLanguage == 'ar' ? data.data.a_title : data.data.title;
                    console.log(this.areasOrRegionId);
                    this.onAreaChange(data.data.id);
                }
            }
            );
        return areaModal.present();
    }
    onAreaChange = (area_id: any) => {
        console.log(area_id);
        this.apiService
            .get(endpoints.slots + '?area_id=' + area_id)
            .pipe(first())
            .subscribe((response) => {
                console.log(response);
                if (response.success) {
                    this.areaSlots = response.data.slots;
                    //console.log(this.areaSlots);
                    this.areaSlots.forEach(data => {
                        data.delivery_start_time = moment(data.start_time, "HH:mm:ss").format("hh:mm A");
                        data.delivery_end_time = moment(data.end_time, "HH:mm:ss").format("hh:mm A");
                    });
                } else if (response.code == 401) {
                    this.events.publish('user:login', { user: null, time: new Date() });
                }
            });
        this.areasOrRegion.map((area) => {
            if (area.id == area_id) { this.governorateId = area.governorate_id }
        });
        console.log(this.governorateId);
    }
    toastMessage = async (msg) => {
        await this.toastCtrl.create({
            message: this.translate.instant(msg),
            duration: 4000,
            position: 'top',
            color: 'light'
        }).then((toastElem) => toastElem.present());
    }
    numberValidation = (event: any) => {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        if (event.target.value.length > 7) {
            event.target.value = event.target.value.slice(0, 7);
        }
        return true;
    }
    openMap = async () => {
        const mapModal = await this.modalCtrl.create({
            component: AutocompletePage,
            componentProps: {
                'latitude': this.latitude,
                'longitude': this.longitude,
                'compound_code': this.compound_code,
                'global_code': this.global_code
            }
        });
        mapModal.onDidDismiss()
            .then((result) => {
                console.log(result);
                if (result.data) {
                    this.latitude = result.data.latitude;
                    this.longitude = result.data.longitude;
                    this.compound_code = result.data.compound_code;
                    this.global_code = result.data.global_code;
                }
            }
            );
        return mapModal.present();
    }
    closeModal = () => {
        this.modalCtrl.dismiss(null, 'cancel');
    }
}
/**
 * 'apartment_info': JSON.stringify(
                {
                    'avenue': this.addUpdateForm.value.avenue ? this.addUpdateForm.value.avenue : '',
                    'floor': this.addUpdateForm.value.floor ? this.addUpdateForm.value.floor : '',
                    'flat': this.addUpdateForm.value.flat ? this.addUpdateForm.value.flat : ''
                }
            ),
            "street_info": JSON.stringify(
                {
                    'street': this.addUpdateForm.value.street,
                    'block': this.addUpdateForm.value.block
                }
            ),
            "house_info": JSON.stringify({'house': this.addUpdateForm.value.house_info}),
 */