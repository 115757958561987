import { Injectable } from '@angular/core';

export interface LoginForm {
    phone: string;
    password: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor() { }
    setAuthToken = (token: string) => {
        window.localStorage.setItem('auth-token', token);
    }

    getAuthToken = () => {
        return window.localStorage.getItem('auth-token');
    }

    setAuthUser = (user) => {
        window.localStorage.setItem('auth-user', JSON.stringify(user));
    }

    getAuthUser = () => {
        return JSON.parse(window.localStorage.getItem('auth-user'));
    }

    setAuthVerify = (status: string) => {
        window.localStorage.setItem('auth-verify', status);
    }

    getAuthVerify = () => {
        return window.localStorage.getItem('auth-verify');
    }

    /*setBiometric = (status: string) => {
        window.localStorage.setItem('auth-biometric', status);
    }

    getBiometric = () => {
        return window.localStorage.getItem('auth-biometric');
    }*/

    logOutUser = () => {
        window.localStorage.removeItem('auth-token');
        window.localStorage.removeItem('auth-user');
        window.localStorage.removeItem('auth-verify');
    }
}
