import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { first } from 'rxjs/operators';

import { endpoints } from 'src/app/constant/endpoints';
import { ApiService } from 'src/app/provider/api.service';
import { ConfigService } from 'src/app/provider/config.service';
import { Events } from 'src/app/provider/events.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.page.html',
    styleUrls: ['./rating.page.scss'],
})
export class RatingPage implements OnInit {
    @Input() date: any;
    @Input() customer_id: any;
    @Input() customer_subscription_id: any;
    shownGroup = null;
    settings: any = {}; loader: boolean = true;
    menuData: any = {}; selectedFood: any = [];
    customer: any;
    constructor(
        private events: Events,
        private toastCtrl: ToastController,
        private modalCtrl: ModalController,
        private loadingCtrl: LoadingController,
        private translate: TranslateService,
        private configService: ConfigService,
        private authService: AuthService,
        private apiService: ApiService,
    ) {
        this.events
            .subscribe('rating:change', (ev: any) => {
                this.toggleGroup(ev.menuId);
                this.apiMenuCaller(this.date);
            });
    }
    ionViewWillEnter() {
        this.customer = this.authService.getAuthUser();
        this.apiMenuCaller(this.date);
    }
    ngOnInit() {
        console.log(this.date);
        this.configService.getAppSettings()
            .then((settingData: any) => {
                this.settings = JSON.parse(settingData);
                //console.log(this.settings);
                this.translate.setDefaultLang(this.settings.appLanguage);
                this.translate.use(this.settings.appLanguage);
            }
            );
    }
    apiMenuCaller = (givenDate: Date) => {
        this.menuData = {};
        this.selectedFood = [];
        console.log(givenDate);
        this.loader = true;
        const date = moment(givenDate).format('YYYY/MM/DD');
        this.apiService
            .get(endpoints.menus + '?date=' + date + '&customer_id=' + this.customer.id)
            .pipe(first())
            .subscribe(response => {
                console.log(response);
                this.loader = false;
                if (Object.entries(response?.data?.menu_builders).length > 0) {
                    this.menuData = response.data.menu_builders;
                } else {
                    this.menuData = {};
                }
                Object.keys(this.menuData).forEach(key => {
                    //console.log(this.menuData[key]);
                    this.menuData[key].type_data.map(item => {
                        //console.log(item);
                        if (item.is_selected) {
                            this.selectedFood.push(item);
                        }
                    });
                });
                console.log(this.selectedFood);
                //console.log(this.selectedFood);
            }, error => {
                console.log(error);
                this.loader = false;
                this.menuData = {};
            });
    }
    emptyObject = (data) => {
        if (Object.entries(data).length > 0) {
            return false
        }
        return true;
    }
    toggleGroup = (group: any) => {
        if (this.isGroupShown(group)) {
            this.shownGroup = null;
        } else {
            this.shownGroup = group;
        }
    }
    isGroupShown = (group: any) => {
        return this.shownGroup === group;
    }
    closeModal = () => {
        this.modalCtrl.dismiss();
    }
    showLoading = async () => {
        this.loadingCtrl.create({
            message: this.translate.instant('please_wait'),
            cssClass: 'loader_class',
        }).then(a => {
            a.present().then(() => {
                console.log('presented');

            });
        });
    }

    hideLoading = async () => {
        setTimeout(async () => {
            try {
                this.loadingCtrl.dismiss();
            } catch (error) {
                console.log(error);
            }
        }, 1000);
    }

    toastMessage = async (msg) => {
        await this.toastCtrl.create({
            message: this.translate.instant(msg),
            duration: 4000,
            position: 'top',
            color: 'tertiary'
        }).then(toastElem => toastElem.present());
    }   
    getParseFloat = (num: any) => {
        return Number(parseFloat(num).toFixed(0));
    }
}
