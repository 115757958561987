import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
    selector: 'app-area-lists',
    templateUrl: './area-lists.page.html',
    styleUrls: ['./area-lists.page.scss'],
})
export class AreaListsPage implements OnInit {
    @Input() citylist: any = {};
    @Input() orginallist: any = {};
    constructor(
        public router: Router,
        public platform: Platform,
        public modalCtrl: ModalController,
    ) {
    }
    ngOnInit() {
        console.log(this.citylist, this.orginallist);
    }
    getCityList = (ev: any) => {
        var val = ev.target.value;
        this.citylist = this.orginallist;
        if (val && val.trim() != '') {
            this.citylist = this.orginallist
                .filter((city) => {
                    if (this.platform.isRTL)
                        return (city.title.indexOf(val) > -1 || city.a_title.indexOf(val) > -1 || city.description.toLowerCase().indexOf(val.toLowerCase()) > -1);
                    else
                    return (city.title.indexOf(val) > -1 || city.description.toLowerCase().indexOf(val.toLowerCase()) > -1);
                }
                );
        }
    }
    clearCityLists = () => {
        this.citylist = this.orginallist;
    }
    selectedCityItem = (cityData: any) => {
        this.modalCtrl.dismiss(cityData, 'close');
    }
    closeModal = () => {
        this.modalCtrl.dismiss(null, 'cancel');
    }
}
