import { Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-citation-modal',
  templateUrl: './citation-modal.page.html',
  styleUrls: ['./citation-modal.page.scss'],
})
export class CitationModalPage {
    settings: any = {};
    content: any;
    language: string = 'en';
    constructor(
        public modalCtrl: ModalController,
    ) { }

  dismissModal() {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss();
    } else {
      console.error('IonModal reference is undefined.');
    }
  }
}
