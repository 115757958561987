import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    settings: any = {
        appName: environment.APP_NAME,
        appLanguage: 'en',
        appDirection: 'ltr',
        appNotification: false,
        appDarkMode: false,
        currentUser: null,
    };
    constructor(
    ) {
    }
    getAppSettings = () => {
        return new Promise(async (resolve) => {
            const { value } = await Preferences.get({ key: 'appSettings' });
            if (value) {
                resolve(value);
            } else {
                await Preferences.set({
                    key: 'appSettings',
                    value: JSON.stringify(this.settings),
                });
                resolve(JSON.stringify(this.settings));
            }
        });
    }
    setStorage = async (keyStorage, givenSettings: any) => {
        await Preferences.set({
            key: keyStorage,
            value: JSON.stringify(givenSettings),
        });
    };
    getStorage = async (keyStorage: string) => {
        const { value } = await Preferences.get({ key: keyStorage });
        return JSON.parse(value);
    };
    removeStorage = async (keyStorage: string) => {
        await Preferences.remove({ key: keyStorage });
    };
}
