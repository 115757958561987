import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { endpoints } from 'src/app/constant/endpoints';
import { ApiService } from 'src/app/provider/api.service';
import { Events } from 'src/app/provider/events.service';

@Component({
    selector: 'app-rating-form',
    templateUrl: './rating-form.component.html'
})
export class RatingComponentForm implements OnInit {
    @Input() menuId: any
    @Input() date: any
    @Input() customerId: any;
    @Input() customerSubscriptionId: any;

    ratingForm: FormGroup;
    constructor(
        private events: Events,
        private loadingCtrl: LoadingController,
        private toastCtrl: ToastController,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
    ) {
    }
    ngOnInit(): void {
        console.log(this.menuId, this.customerId);
        this.ratingForm = this.formBuilder.group({
            comment: new FormControl('', Validators.compose([])),
            rate: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(1),
            ])),
            menu_item_id: new FormControl(this.menuId, Validators.compose([
                Validators.required,
            ])),
            date: new FormControl(this.date, Validators.compose([
                Validators.required,
            ])),
            customer_id: new FormControl(this.customerId, Validators.compose([
                Validators.required,
            ])),
            customer_subscription_id: new FormControl(this.customerSubscriptionId, Validators.compose([
                Validators.required,
            ])),
        });
    }
    submitRating = () => {
        this.showLoading();
        console.log(this.ratingForm.value);
        this.apiService
            .post(endpoints.sendRating, this.ratingForm.value)
            .subscribe((resp) => {
                console.log(resp);
                this.events.publish('rating:change', { menuId: this.menuId, time: new Date() });
                this.hideLoading();
                this.toastMessage(resp.message);
        },error => {
            console.log(error);
        });
    }
    showLoading = async () => {
        this.loadingCtrl.create({
            message: this.translate.instant('please_wait'),
            cssClass: 'loader_class',
        }).then(a => {
            a.present().then(() => {
                console.log('presented');

            });
        });
    }

    hideLoading = async () => {
        setTimeout(async () => {
            try {
                this.loadingCtrl.dismiss();
            } catch (error) {
                console.log(error);
            }
        }, 1000);
    }

    toastMessage = async (msg) => {
        await this.toastCtrl.create({
            message: this.translate.instant(msg),
            duration: 4000,
            position: 'top',
            color: 'light'
        }).then(toastElem => toastElem.present());
    }
}