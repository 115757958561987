import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { endpoints } from 'src/app/constant/endpoints';
import { ApiService } from 'src/app/provider/api.service';
import _ from 'lodash';


@Component({
    selector: 'app-aboutus',
    templateUrl: './aboutus.page.html',
    styleUrls: ['./aboutus.page.scss'],
})
export class AboutusPage implements OnInit {
    content: any;
    content_ar: any;
    constructor(
        public platform: Platform,
        public modalCtrl: ModalController,
        private apiService: ApiService
    ) { }

    ngOnInit() {
        this.apiService.
            get(endpoints.cms)
            .subscribe((res) => {
                console.log(res);
                this.content = _.filter(res.data.pages, (val) => val.title == "Terms & Conditions")[0].content;
                this.content_ar = _.filter(res.data.pages, (val) => val.title == "Terms & Conditions")[0].a_content;
                console.log('asli');
            });
    }

    closeModal = () => {
        this.modalCtrl.dismiss();
    }

}
