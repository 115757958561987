import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/provider/config.service';

@Component({
    selector: 'app-subscription-detail',
    templateUrl: './subscription-detail.page.html',
    styleUrls: ['./subscription-detail.page.scss'],
})
export class SubscriptionDetailPage implements OnInit {
    @Input() menu: any;
    settings: any = {};
    dummyImg = "../../assets/dummy.png";
    constructor(
        private modalCtrl: ModalController,
        private configService: ConfigService,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        console.log(this.menu);
        this.configService.getAppSettings()
            .then((settingData: any) => {
                this.settings = JSON.parse(settingData);
                //console.log(this.settings);
                this.translate.setDefaultLang(this.settings.appLanguage);
                this.translate.use(this.settings.appLanguage);
            }
            );
    }
    closeModal = () => {
        this.modalCtrl.dismiss(null, 'cancel');
    }
}
