
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Events } from '../provider/events.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private events: Events,
        private toastCtrl: ToastController,
        private translate: TranslateService
    ) {
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        if (window.localStorage.getItem('auth-token') !== 'null' &&
            window.localStorage.getItem('auth-token') != null &&
            window.localStorage.getItem('auth-verify') != 'false'
        ) {
            return true;
        } else {
            this.events.publish('user:login', { user: null, time: new Date() });
            this.toastCtrl.create({
                message: this.translate.instant('login_to_access_app'),
                duration: 30,
                position: 'top',
                color: 'light'
            }).then(toastElem => toastElem.present());
            return false;
        }
    }
}
