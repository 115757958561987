import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import _ from 'lodash';

@Component({
	selector: 'app-customization-modal',
	templateUrl: './customization-modal.page.html',
	styleUrls: ['./customization-modal.page.scss'],
})
export class CustomizationModalPage implements OnInit {
	@Input() menuItem: any;
	customizations: any = [];
	customizationsSingleApply: boolean = true;
	customizationsMultipleApply: boolean = true;
	constructor(
		public modalCtrl: ModalController,
	) { }

	ngOnInit() {
		this.customizations = this.menuItem.customizations;
		console.log(this.menuItem);
		this.customizations.map((customData: any) => {
			console.log(customData);
			customData.options.map(option => {
				option.is_selected = false;
				option.disabled = false;
				if (customData.selection_type == 'single') {
					this.customizationsSingleApply = false;
				} else if (customData.selection_type == 'multiple') {
					this.customizationsMultipleApply = false;
				}
			});
		});
	}
	singleOptionChanged = (event: any, custom: any) => {
		console.log(event);
		console.log(custom);
		this.customizationsSingleApply = false;
		if (event.detail.value) {
			this.customizations.map((customData: any) => {
				if (customData.name == custom.name) {
					customData.options.map(option => {
						option.is_selected = false;
						if (event.detail.value == option.option_id) {
							option.is_selected = true;
							this.customizationsSingleApply = true;
						}
					});
				}
			});
		}
	}
	multipleOptionChanged = (event: any, custom: any) => {
		console.log(event);
		console.log(custom);
		let selectedArr = _.filter(custom.options, (val) => val.is_selected == true);
		console.log(selectedArr);
		this.customizationsMultipleApply = false;
		this.customizations.map((customData: any) => {
			if (customData.name == custom.name) {
				customData.options.map(option => {
					option.is_selected = option.is_selected ? true : false;
					if (event.detail.checked && event.detail.value == option.option_id) {
						option.disabled = false;
						console.log(selectedArr.length);
						option.is_selected = true;
						this.customizationsMultipleApply = true;
						if (Number(selectedArr.length + 1) > Number(custom.maximum_selection_limit - 1)) {
							_.forEach(customData.options, (item) => {
								if (!item.is_selected) {
									item.disabled = true;
								}
							});
						}
					} else if (!event.detail.checked && event.detail.value == option.option_id) {
						option.is_selected = false;
						_.forEach(customData.options, (item) => {
							item.disabled = false;
						});

					}
				});
			}
		});
	}
	applyModal = () => {
		this.modalCtrl.dismiss({ customizations: this.customizations }, 'apply');
	}
	denyModal = () => {
		this.modalCtrl.dismiss({ customizations: this.customizations }, 'deny');
	}
}
