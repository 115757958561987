import { Component, Input, OnInit } from '@angular/core';
import { LoadingController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import _ from "lodash";

import { endpoints } from 'src/app/constant/endpoints';
import { ApiService } from 'src/app/provider/api.service';
import { ConfigService } from 'src/app/provider/config.service';
import { Events } from 'src/app/provider/events.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-selection',
    templateUrl: './selection.page.html',
    styleUrls: ['./selection.page.scss'],
})
export class SelectionPage implements OnInit {
    @Input() date: any;
    @Input() customer_id: any;
    @Input() customer_subscription_id: any;

    settings: any = {}; loader: boolean = true;
    menuData: any = {}; selectedFood: any = [];
    customer: any;
    storeData: any;
    constructor(
        private events: Events,
        private toastCtrl: ToastController,
        private modalCtrl: ModalController,
        private loadingCtrl: LoadingController,
        private translate: TranslateService,
        private configService: ConfigService,
        private authService: AuthService,
        private apiService: ApiService,
    ) {
        this.events
            .subscribe('rating:change', (ev: any) => {
                this.apiMenuCaller(this.date);
            });
    }
    ionViewWillEnter() {
        this.customer = this.authService.getAuthUser();
        this.apiMenuCaller(this.date);
    }
    ngOnInit() {
        console.log(this.date);
        this.configService.getAppSettings()
            .then((settingData: any) => {
                this.settings = JSON.parse(settingData);
                //console.log(this.settings);
                this.translate.setDefaultLang(this.settings.appLanguage);
                this.translate.use(this.settings.appLanguage);
            }
            );
    }
    apiMenuCaller = (givenDate: Date) => {
        this.menuData = {};
        this.selectedFood = [];
        console.log(givenDate);
        this.loader = true;
        const date = moment(givenDate).format('YYYY/MM/DD');
        this.apiService
            .get(endpoints.menus + '?date=' + date + '&customer_id=' + this.customer.id)
            .pipe(first())
            .subscribe(response => {
                console.log(response);
                this.loader = false;
                if (response.success && response.data?.menu_builders.length > 0) {
                    response?.data?.menu_builders.map(menu => {
                        menu.type_data.map(item => {
                            if (item.is_selected) {
                                this.selectedFood.push(item);
                            }
                            item.replaceable_menu_items.length > 0 && item.replaceable_menu_items.map((replaceable_menu) => {
                                if (replaceable_menu.is_selected) {
                                    this.selectedFood.push(replaceable_menu);
                                }
                            });
                        });
                    });
                    console.log(this.selectedFood);
                } else if (response.code == 401) {
                    this.events.publish('user:login', { user: null, time: new Date() });
                } else {
                    this.toastMessage(response.message);
                }
            }, error => {
                console.log(error);
                this.loader = false;
                this.menuData = {};
            });
    }
    clearMenuApi = async (item: any) => {
        const apiQuery = {
            'customer_id': this.customer.id,
            'date': moment(this.date).format('YYYY-MM-DD'),
        }
        if (item != null) {
            Object.assign(apiQuery, { 'selected_menu_item_id': item.selected_menu_item_id });
        }
        console.log(apiQuery);
        this.showLoading();
        let status = false;
        await this.apiService
            .post(endpoints.clearMenu, apiQuery)
            .pipe(first())
            .subscribe(response => {
                console.log(response);
                this.hideLoading();
                if (response.code == 201) {
                    this.toastMessage(response.message);
                    status = true;
                } else if (response.code == 401) {
                    this.events.publish('user:login', { user: null, time: new Date() });
                    status = false;
                } else {
                    this.toastMessage(response.message);
                    status = false;
                }
            }, error => {
                status = false;
            });
        return status;
    }
    clearOne = async (item: any) => {

        
        console.log(item);
        if ( moment(this.date).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD')){
        const result = await this.clearMenuApi(item);
       
        // console.log('result: ', result);
        if (true) {
            this.selectedFood.map(menu_item => {
                console.log(menu_item);
                if (menu_item.id == item.id) {
                    menu_item.is_selected = false;
                }
            
            this.selectedFood = _.filter(this.selectedFood, function (val) { return val.is_selected; });
        });
        }
    }{
        this.toastMessage('Cant Delete same day menu');
    }
    }
    clearAllSelection = async () => {
        if ( moment(this.date).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD')){
            const result = await this.clearMenuApi(null);
           
        
        // console.log('result: ', result);
        if (true) {
            this.selectedFood = [];
        }
    }{
        this.toastMessage('Cant Delete same day menu');
    }
    }
    navigateToMenu = () => {
        this.modalCtrl.dismiss({ redirection: true });
    }
    emptyObject = (data) => {
        if (Object.entries(data).length > 0) {
            return false
        }
        return true;
    }
    closeModal = () => {
        this.modalCtrl.dismiss();
    }
    showLoading = async () => {
        this.loadingCtrl.create({
            message: this.translate.instant('please_wait'),
            cssClass: 'loader_class',
        }).then(a => {
            a.present().then(() => {
                console.log('presented');

            });
        });
    }

    hideLoading = async () => {
        setTimeout(async () => {
            try {
                this.loadingCtrl.dismiss();
            } catch (error) {
                console.log(error);
            }
        }, 1000);
    }

    toastMessage = async (msg) => {
        await this.toastCtrl.create({
            message: this.translate.instant(msg),
            duration: 1000,
            position: 'top',
            color: 'light'
        }).then(toastElem => toastElem.present());
    }

    getParseFloat = (num: any) => {
        return Number(parseFloat(num).toFixed(0));
    }


}
