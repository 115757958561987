import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Platform, ModalController, ToastController, LoadingController, NavController, IonSlides } from '@ionic/angular';
import { FormGroup, FormBuilder, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { SwiperContainer } from 'swiper/element';
import { first } from 'rxjs/operators';

import { endpoints } from 'src/app/constant/endpoints';
import { Events } from '../provider/events.service';
import { ApiService } from 'src/app/provider/api.service';
import { ConfigService } from 'src/app/provider/config.service';
import { AreaListsPage } from '../pages/area-lists/area-lists.page';
import { AuthService } from '../services/auth.service';
import { OtpVerificationPage } from '../pages/otp-verification/otp-verification.page';
import { TermsAndConditionsPage } from '../pages/terms-and-conditions/terms-and-conditions.page';

@Component({
    selector: 'app-signup-modal',
    templateUrl: './signup-modal.page.html',
    styleUrls: ['./signup-modal.page.scss'],
})
export class SignupModalPage implements OnInit, AfterViewInit {
    @ViewChild('swiperJourney', { static: false }) slidesJ!: ElementRef<SwiperContainer>;
    settings: any = {}; storeData: any = {}; loading: boolean = false;
    slideOpts = {
        initialSlide: 0,
        slidesPerView: 1,
    }
    showPwd: boolean = false;
    passwordType: any = 'password';
    signUpForm: FormGroup;
    loginForm: FormGroup;
    country_iso_code: string = "KW";
    redirectTo: string = 'signin';
    historyRoute: string = 'signin';

    countryLists: any = [];
    city_id: number = 0;
    userPhoneCode: string = '965';
    userPhoneAllowDigit: number = 8;
    areasOrRegion: any = [];
    areasOrRegionId: any = null;
    areasOrRegionName: string;
    TcChecked: boolean = false;

    accessToken: any;
    pushToken: string = 'demo';
    customer: any;
    logo: string = '';
    constructor(
        private device: Device,
        private events: Events,
        private activeRoute: ActivatedRoute,
        private platform: Platform,
        private formBuilder: FormBuilder,
        private navCtrl: NavController,
        private modalCtrl: ModalController,
        private toastCtrl: ToastController,
        private loadingCtrl: LoadingController,
        private translate: TranslateService,
        private apiService: ApiService,
        private authService: AuthService,
        private configService: ConfigService,
    ) {
        this.activeRoute.queryParams.subscribe((params: any) => {
            if (params) {
                this.historyRoute = params.historyRoute;
                this.redirectTo = params.redirectTo;
            }
        });
        this.events.subscribe('payload:configuration', (payload: any) => {
            console.log(payload.appPayload);
            this.storeData = payload.appPayload;
        });
        this.apiService
            .get(endpoints.areas)
            .pipe(first())
            .subscribe((response) => {
                console.log(response);
                if (response.success) {
                    this.areasOrRegion = response.data.areas;
                    //console.log(this.areasOrRegion);
                    //this.areasOrRegionId = response.data.areas[0].id;
                }
            });
        this.signUpForm = this.formBuilder.group({
            areasOrRegionId: new FormControl(this.areasOrRegionId, Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(20),
            ])),
            name: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(20),
            ])),
            email: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(50),
                Validators.pattern('^[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$'),
            ])),
            phone: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(8)
            ])),
            country_iso_code: new FormControl(this.country_iso_code,
                Validators.compose([])
            ),
            password: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(30),
            ])),
            device_type: this.platform.is('android') ? 'android' : this.platform.is('ios') ? 'ios' : 'web',
            device_token: this.platform.is('capacitor') ? this.device.uuid : 'demo',
            TcChecked: new FormControl(this.TcChecked, Validators.compose([
                Validators.required,
                this.equaltoTrue('TcChecked')
            ])),
        });
        this.loginForm = this.formBuilder.group({
            phone: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(8),
            ])),
            //country_iso_code: new FormControl(this.country_iso_code, Validators.compose([])),
            password: new FormControl('', Validators.compose([
                Validators.required,
                Validators.minLength(6),
                Validators.maxLength(30),
            ])),
            device_token: new FormControl(this.pushToken, Validators.compose([
                Validators.required,
            ])),
            device_type: this.platform.is('android') ? 'android' : this.platform.is('ios') ? 'ios' : 'web',
        });
    }
    async ngOnInit() {
        this.storeData = await this.configService.getStorage('appPayload');
        this.logo = this.storeData?.configurations?.assets?.logo;
        this.userPhoneCode = this.storeData.dialing_code ? this.storeData.dialing_code : this.userPhoneCode;
        this.userPhoneAllowDigit = this.storeData.allowed_digit_for_phone ? this.storeData.allowed_digit_for_phone : this.userPhoneAllowDigit;
        this.configService.getAppSettings()
            .then((settingData: any) => {
                this.settings = JSON.parse(settingData);
                //console.log(this.settings);
                this.translate.setDefaultLang(this.settings.appLanguage);
                this.translate.use(this.settings.appLanguage);
            }
            );
    }
    ngAfterViewInit() {
        //this.slidesJ?.nativeElement?.swiper?.lockSwipes(true);
    }
    slideToNext = () => {
        //this.slidesJ?.nativeElement?.swiper?.allowSlideNext(false);
        this.slidesJ?.nativeElement?.swiper?.slideTo(1);
        //this.slidesJ?.nativeElement?.swiper?.lockSwipes(true);
    }
    slideToPrev = () => {
        //this.slidesJ?.nativeElement?.swiper?.lockSwipes(false);        
        this.slidesJ?.nativeElement?.swiper?.slideTo(0);
        //this.slidesJ?.nativeElement?.swiper?.lockSwipes(true);
    }
    onSubmitSignUp = async () => {
        console.log(this.signUpForm.value);
        const journeyData = await this.configService.getStorage('journeyData');
        console.log(journeyData);
        if (journeyData != null && this.historyRoute == 'journey-result') { Object.assign(this.signUpForm.value, journeyData); }
        this.showLoading();
        this.apiService
            .post(endpoints.register, this.signUpForm.value)
            .pipe(first())
            .subscribe(async (response) => {
                console.log(response);
                this.hideLoading();
                if (response.success) {
                    this.accessToken = response.data.customer.session_token;
                    this.customer = response.data.customer;
                    this.authService.setAuthToken(this.accessToken);
                    this.authService.setAuthUser(this.customer);
                    this.authService.setAuthVerify('false');
                    const otpModal = await this.modalCtrl
                        .create({
                            component: OtpVerificationPage,
                            componentProps: {
                                phone: this.signUpForm.value.phone,
                                email: this.signUpForm.value.email,
                                country_iso_code: this.country_iso_code,
                                otp_sent: true
                            }
                        });
                    otpModal.onDidDismiss()
                        .then((result: any) => {
                            console.log(result);
                            if (result.data) {
                                this.authService.setAuthVerify('true');
                                this.modalCtrl.dismiss({ customer: this.customer }, 'cancel');
                            } else {
                                this.authService.setAuthToken(null);
                                this.authService.setAuthUser(null);
                                this.authService.setAuthVerify('false');
                                this.modalCtrl.dismiss(false, 'cancel');
                            }
                        }
                        );
                    return otpModal.present();
                } else {
                    this.toastMessage(response.message);
                }
            }, (err) => {
                this.hideLoading();
                if (err.status == 422) {
                    this.toastMessage("please_fill_all_mandatory_fields");
                } else if (err.status == 400) {
                    this.toastMessage(err.error.message);
                }
            });
    }
    onSubmitSignIn = () => {
        this.loading = true;
        //this.showLoading();
        console.log(this.loginForm.value);
        Object.assign(this.loginForm.value, { 'device_token': this.pushToken });
        //alert(JSON.stringify(this.loginForm.value));
        this.apiService
            .post(endpoints.login, this.loginForm.value)
            .pipe(first())
            .subscribe(async (response) => {
                this.loading = false;
                //this.hideLoading();
                console.log(response);
                if (response.success) {
                    this.authService.setAuthToken(response.data.customer.session_token);
                    this.authService.setAuthUser(response.data.customer);
                    this.authService.setAuthVerify('false');
                    if (!response.data.customer.is_verified) {
                        let otpModal = await this.modalCtrl
                            .create({
                                component: OtpVerificationPage,
                                componentProps: {
                                    phone: this.loginForm.value.phone,
                                    email: '',
                                    country_iso_code: this.country_iso_code,
                                    otp_sent: false
                                }
                            });
                        otpModal.onDidDismiss()
                            .then((data) => {
                                console.log(data);
                                if (data.data) {
                                    this.authService.setAuthVerify('true');
                                    this.modalCtrl.dismiss({ customer: response.data.customer }, 'cancel');
                                }
                            }
                            );
                        return otpModal.present();
                    } else {
                        this.authService.setAuthVerify('true');
                        this.modalCtrl.dismiss({ customer: response.data.customer }, 'cancel');
                    }
                } else {
                    this.toastMessage(response.message);
                }
            }, (err) => {
                this.loading = false;
                //this.hideLoading();
                console.log(typeof err.error);
                if (err.error.message) {
                    console.log(err.error.message);
                    this.toastMessage(err.error.message);
                } else {
                    Object.keys(err.error).forEach((key) => {
                        let errorArray = err.error[key];
                        Object.keys(errorArray).forEach((index) => {
                            this.toastMessage(errorArray[index]);
                        });
                    });
                }
            });
    }
    numberValidation = (event: any) => {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        if (event.target.value.length > Number(this.userPhoneAllowDigit - 1)) {
            event.target.value = event.target.value.slice(0, Number(this.userPhoneAllowDigit - 1));
        }
        return true;
    }
    showPassword = () => {
        if (this.passwordType === 'text') {
            this.passwordType = 'password';
        } else {
            this.passwordType = 'text';
        }
    }
    openAreaModal = async () => {
        const areaModal = await this.modalCtrl.create({
            component: AreaListsPage,
            componentProps: {
                'city_id': this.city_id,
                'citylist': this.areasOrRegion,
                'orginallist': this.areasOrRegion
            }
        });
        areaModal.onDidDismiss()
            .then((data) => {
                console.log(data);
                if (data.data) {
                    console.log(data.data);
                    this.city_id = data.data.id;
                    this.areasOrRegionId = data.data.id;
                    this.areasOrRegionName = data.data.title
                }
            }
            );
        return areaModal.present();
    }
    onTCModal = async () => {
        const termsModal = await this.modalCtrl
            .create({
                component: TermsAndConditionsPage,
                cssClass: "terms-modal",
            });
        termsModal.onDidDismiss()
            .then((data) => {
                this.TcChecked = data.data;
            }
            );
        return termsModal.present();
    }
    equaltoTrue = (field_name): ValidatorFn => {
        console.log(field_name);
        return (control: AbstractControl): { [key: string]: any } => {
            let isValid = control.value;
            console.log(isValid);
            if (!isValid) {
                return { 'equalTo': { isValid } }
            } else {
                return null;
            };
        }
    }
    toastMessage = async (msg) => {
        await this.toastCtrl.create({
            message: this.translate.instant(msg),
            duration: 4000,
            position: 'top',
            color: 'primary'
        }).then(toastElem => toastElem.present());
    }
    showLoading = async () => {
        this.loadingCtrl.create({
            message: this.translate.instant('please_wait'),
            cssClass: 'loader_class',
        }).then(a => {
            a.present().then(() => {
                console.log('presented');
            });
        });
    }
    hideLoading = async () => {
        setTimeout(async () => {
            try {
                this.loadingCtrl.dismiss();
            } catch (error) {
                console.log(error);
            }
        }, 1000);
    }
    navigateToPage = (page: any) => {
        this.navCtrl.navigateForward([page]);
    }
    closeModal = () => {
        this.modalCtrl.dismiss(false, 'cancel');
    }
}