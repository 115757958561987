import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccountService } from '../provider/account.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService {
    apiToken: any = '';
    constructor(private accountService: AccountService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.accountService.getAuthToken() != "") {
            req = req.clone({
                setHeaders: {
                    'Authorization': `${this.accountService.getAuthToken()}`,
                    'identifier': environment.IDENTIFIER,
                }
            });
        }
        else {
            req = req.clone({
                setHeaders: {
                    'accept': 'application/json'
                }
            });
        }
        return next.handle(req);
    }
}
