import { Component, ViewChildren, QueryList, OnInit } from '@angular/core';
import { Platform, IonRouterOutlet, AlertController, ModalController, ToastController, NavController, } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { TranslateService } from '@ngx-translate/core';

import { Router } from '@angular/router';
import { Events } from './provider/events.service';
import { AuthService } from './services/auth.service';
import { ApiService } from './provider/api.service';
import { ConfigService } from './provider/config.service';
import { endpoints } from './constant/endpoints';
import { register } from 'swiper/element/bundle';
register();

import { stringify } from 'querystring';
import { Preferences } from '@capacitor/preferences';
import { first } from 'rxjs';
import * as moment from 'moment';
import { DataService } from './services/data.service';



@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
	@ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
	APP_VERSION = '1.0.0';
	settings: any = {};
	backButton = true;
	connectedToInternet: boolean = true;
	lastTimeBackPress = 0;
	timePeriodToExit = 2000;
	splash = true;

today = moment().format("YYYY-MM-DD");
colorprimary: any;
colorsecondary: any;
colortertiary: any;
p2: any;
s2: any;
t2: any;
p3: any;
s3: any;
t3: any;
shadowlight: any;
shadowdark: any;
shadowcolorlight: any;
shadowcolordark: any;
bglight: any;
bgdark: any;
highlight: any;
highdark: any;
diffuselight: any;
diffusedark: any;
diffuselightselected: any;
diffusedarkselected: any;
colorlight: any;
colordark: any;
colorback: any;
colorbackdark: any;
greenmacro: any;
redmacro: any;
yellowmacro: any;
shadowhighlight: any;
shadowhighdark: any;
lowNutrition: any;
highNutrition:any;
avgNutrition:any;
carbcolor:any;
caloriecolor:any;
protcolor:any;
fatcolor:any;

	serverVersion: any = 0;
	splittedVersion: any;
	androidURL = 'https://play.google.com/store/apps/details?id=com.dietmaster1';
	iosURL = "https://apps.apple.com/us/app/dietmaster/id1600484121";
	protcolorlight: string;
	fatcolorlight: string;
	carbcolorlight: string;
	caloriecolorlight: string;
	weekMenuData: any[];
	customer: any;
	daysafter: number;


	constructor(
		private router: Router,
		private platform: Platform,
		private events: Events,
		private network: Network,
		private appVersion: AppVersion,
		private inAppBrowser: InAppBrowser,
		private navCtrl: NavController,
		private toastCtrl: ToastController,
		private modalCtrl: ModalController,
		private alertCtrl: AlertController,
		private translate: TranslateService,
		private screenOrientation: ScreenOrientation,
		private authService: AuthService,
		private configService: ConfigService,
		private apiService: ApiService,
		private dataService: DataService
	) {
		this.customer = this.authService.getAuthUser();

		function hextorgb(hex: string): {r: number, g: number, b: number} {
			// Remove the hash at the start if it's there
			if (typeof hex == 'string') {
				hex = hex.replace(/^#/, '');
			  } else {
				console.error('Invalid hex value:', hex);
			  }
		  
			// Parse the r, g, b values
			let bigint = parseInt(hex, 16);
			let r = (bigint >> 16) & 255;
			let g = (bigint >> 8) & 255;
			let b = bigint & 255;
		  
			return {r, g, b};
		  }
		  function convertopacity(rgb: {r: number, g: number, b: number}, alpha: number): string {
			return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
		  }

		  function rgbonly(rgb: {r: number, g: number, b: number}): string {
			return `${rgb.r}, ${rgb.g}, ${rgb.b}`;
		  }

		  function hexToHsl(hex: string): {h:number, s:number, l:number} {
			
			let r = 0, g = 0, b = 0;
			
			
			if (hex.length == 4) {
			  r = parseInt(hex[1] + hex[1], 16);
			  g = parseInt(hex[2] + hex[2], 16);
			  b = parseInt(hex[3] + hex[3], 16);
			} else if (hex.length == 7) {
			  r = parseInt(hex[1] + hex[2], 16);
			  g = parseInt(hex[3] + hex[4], 16);
			  b = parseInt(hex[5] + hex[6], 16);
			}
		  
			r /= 255;
			g /= 255;
			b /= 255;
		  
			const max = Math.max(r, g, b);
			const min = Math.min(r, g, b);
			
			let h = 0, s = 0, l = (max + min) / 2;
		  
			if (max !== min) {
				
			  const delta = max - min;
			  s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);
		  
			  switch (max) {
				case r: h = (g - b) / delta + (g < b ? 6 : 0); break;
				case g: h = (b - r) / delta + 2; break;
				case b: h = (r - g) / delta + 4; break;
			  }
			  h /= 6;
			  h = Math.round(h * 360); 
			  s = Math.round(s * 100); 
			  l = Math.round(l * 100); 
		  
			}
			
			return {h, s, l};
			;
		  }

		  function hslshadowvar(hsl: {h: number, s: number, l: number}): string {

			return `${hsl.h}deg ${hsl.s}% ${hsl.l}%` ;
		  }
		  function hslmaker(hsl: {h: number, s: number, l: number}): string {

			return `hsl(${hsl.h}deg ${hsl.s}% ${hsl.l}%)` ;
		  }

		  function hslshadowvard(hsl: {h: number, s: number, l: number}): string {
			// let s = hsl.s + ((hsl.s*50)/100);
			let newl = 10;

			return `${hsl.h}deg ${hsl.s}% ${newl}%` ;
		  }

		  function hslshadowvarl(hsl: {h: number, s: number, l: number}): string {
			// let h = 10;
			// let s = hsl.s + ((hsl.s*50)/100);
			let newl = 5;

			return `${hsl.h}deg ${hsl.s}% ${newl}%` ;
		  }

		 
		  function hslcolordark(hsl: {h: number, s: number, l: number}): string {
			const newS = 90;  
			const newL = 5;  
			return  `hsl(${hsl.h}deg ${newS}% ${newL}%)`;
		  };

		  function hslcolorlight(hsl: {h: number, s: number, l: number}): string {
			const newS = 10;  
			const newL = 95;  
			return  `hsl(${hsl.h}deg ${newS}% ${newL}%)`;
		  };

		//   function hslshadowcolorlight(hsl: {h: number, s: number, l: number}): {h: number, s: number, l: number} {
		// 	const newS = 50;  
		// 	const newL = 20;  
		// 	return { h: hsl.h, s: newS, l: newL };
		//   };
		//   function hslshadowcolordark(hsl: {h: number, s: number, l: number}): {h: number, s: number, l: number} {
		// 	const newS = 90;  
		// 	const newL = 5;  
		// 	return { h: hsl.h, s: newS, l: newL };
		//   };

		  function hslshadowcolorlight(hsl: {h: number, s: number, l: number}): {h: number, s: number, l: number} {
			let newS = Math.max(hsl.s - 30, 0);  // Reduce saturation
			let newL = Math.max(hsl.l - 20, 0);  // Darken the shadow
			return { h: hsl.h, s: newS, l: newL };
		  }

		  function hslshadowcolordark(hsl: {h: number, s: number, l: number}): {h: number, s: number, l: number} {
			let newS = Math.min(hsl.s + 10, 100); // Slightly increase saturation
			let newL = Math.max(hsl.l - 50, 0);   // Drastically reduce lightness
			return { h: hsl.h, s: newS, l: newL };
		  }

		  function bglightconvert(hsl: {h: number, s: number, l: number}): string {
			let s = hsl.s + ((hsl.s*50)/100);
			let l = 95;
			return `hsl(${hsl.h}deg ${s}% ${l}%)`;
		  }

		  function bgdarkconvert(hsl: {h: number, s: number, l: number}): string {
			let s = hsl.s + ((hsl.s*10)/100);
			let l = 5;
			return `hsl(${hsl.h}deg ${s}% ${l}%)`;
		  }

		  function highlightconvert(hsl: {h: number, s: number, l: number}): string {
			let s = 40;
			let l = 60;
			return `hsl(${hsl.h}deg ${hsl.s}% ${l}%)`;
		  }

		  function hsldiffuselight(hsl: {h: number, s: number, l: number}, alpha: number): string {
			let l = 75;
			let s = 10;
			return `hsl(${hsl.h}deg ${s}% ${l}% / ${alpha})`;
		  }
		  function hsldiffusedark(hsl: {h: number, s: number, l: number}, alpha: number): string {
			let l = 5;
			let s = 10;
			return `hsl(${hsl.h}deg ${s}% ${l}% / ${alpha})`;
		  }

		  function hslbackligh(hsl: {h: number, s: number, l: number}): string {
			const newS = hsl.s + ((hsl.s*10)/100); 
			const newL = 80;  
			return  `hsl(${hsl.h}deg ${newS}% ${newL}%)`;
		  };

		  function hslbackdark(hsl: {h: number, s: number, l: number}): string {
			const newS = 5;  
			const newL = 30;  
			return  `hsl(${hsl.h}deg ${newS}% ${newL}%)`;
		  };

		setTimeout(() => {
			this.splash = false;
			this.configService.getAppSettings()
				.then((settingData: any) => {
					this.settings = JSON.parse(settingData);
					//alert(this.settings.appLanguage);
					this.translate.setDefaultLang(this.settings.appLanguage);
					this.translate.use(this.settings.appLanguage);
					this.appUser(this.settings.currentUser);
					if (this.platform.is("capacitor")) {
						this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
						this.checkNetworkConnection();
					}
						document.body.classList.toggle('dark', this.settings.appDarkMode);
						// alert(JSON.stringify(this.fcm));
						// this.fcm.subscribeToTopic('marketing');
						// this.fcm.getToken().then(token => {
						//     alert(token);
						// });
					// }
				}
				);

			this.apiService
				.get(endpoints.configuration)
				.subscribe(async (response) => {
					if (response.success) {
						this.daysafter = response?.settings?.menu_selection_before ? Number(response?.settings?.menu_selection_before) : 0;
						const htmlEl = document.querySelector('html');
						if(response.data?.quote){this.configService.setStorage('appQuote', response.data.quote);}
						// console.log(response.data.organization.configurations, 'shakir');
						
						// this.today = moment(this.today).add(Number(this.daysafter), 'days').format('YYYY-MM-DD');
						// this.loadMenuDataForWeek(this.today);
						// console.log(this.loadMenuDataForWeek(this.today),'weekly');
						  



// 						this.colorprimary = "#0f8ad3";
// 						this.colorsecondary = "#115383";
// 						this.colortertiary = "#093253";
// 						this.p2 = "#115383";
// 						this.s2 = "#0f8ad3";
// 						this.t2 = "#093253";
// 						this.shadowlight = hexToHsl("#1b2757");
// 						this.shadowdark = hexToHsl("#00000");
// 						this.shadowcolorlight = hexToHsl("#3d36ff");
// 						this.shadowcolordark = hexToHsl("#0b1013");
// 						this.bglight = "#0f8ad3";
// 						this.bgdark = "#093253";
// 						this.highlight = "#3ABEF9";
// 						this.highdark = "#3ABEF9";
// 						this.diffuselight = "#0f8ad3";
// 						this.diffusedark = "#0f8ad3";
// 						this.diffuselightselected = "#0f8ad3";
// 						this.diffusedarkselected = "#0f8ad3";
// 						this.colorlight = "#fefefe";
// 						this.colordark = "#1c1c1c";
// 						this.colorback = "#3ABEF9";
// 						this.colorbackdark = "#26355D";
// 						this.p3 = hextorgb("#0f8ad3");
// 						this.s3 = hextorgb("#3572EF");
// 						this.t3 = hextorgb("#093253");
// this.shadowhighlight = "#3ABEF9";
// this.shadowhighdark = "#3ABEF9";

					// 	// #f8f9fa, #e9ecef, #dee2e6, #ced4da, #adb5bd, #6c757d, #495057, #343a40, 


					//    this.t3 = convertopacity(this.t3,0.8);
					//    this.p3 = convertopacity(this.p3,0.5);
					//    this.s3 = convertopacity(this.s3,0.8);
					//    this.diffusedarkselected = convertopacity(this.diffusedarkselected, 0.8);
					//    this.diffuselightselected = convertopacity(this.diffuselightselected, 0.8);
					//    this.diffuselight = convertopacity(this.diffuselight, 0.5);
					//    this.diffusedark = convertopacity(this.diffusedark, 0.5);

						 this.colorprimary = response.data.organization.configurations.theme.primary;
						 this.colorsecondary = response.data.organization.configurations.theme.secondary;
						 this.colortertiary = response.data.organization.configurations.theme.tertiary;

						 this.p2 = response.data.organization.configurations.theme.on_primary;
						 this.s2 = response.data.organization.configurations.theme.on_secondary;
						 this.t2 = response.data.organization.configurations.theme.on_tertiary;

						 this.shadowlight = hexToHsl(response.data.organization.configurations.theme.tertiary);
						 this.shadowlight=hslshadowvard(this.shadowlight);
						 this.shadowdark = hexToHsl(response.data.organization.configurations.theme.on_tertiary);
						 this.shadowdark=hslshadowvarl(this.shadowdark);

						 this.shadowcolorlight = hexToHsl(response.data.organization.configurations.theme.primary);
						 this.shadowcolorlight = hslshadowcolorlight(this.shadowcolorlight);
						 this.shadowcolorlight = hslshadowvar(this.shadowcolorlight);

						 this.shadowcolordark = hexToHsl(response.data.organization.configurations.theme.on_primary);
						 this.shadowcolordark = hslshadowcolordark(this.shadowcolordark);
						 this.shadowcolordark = hslshadowvar(this.shadowcolordark);

						 this.lowNutrition = hslmaker(hexToHsl(response.data.organization.configurations.settings.low_nutrition_highlight));
						 this.avgNutrition = hslmaker(hexToHsl(response.data.organization.configurations.settings.average_nutrition_highlight));
						 this.highNutrition = hslmaker(hexToHsl(response.data.organization.configurations.settings.high_nutrition_highlight));

						 this.caloriecolor = hslmaker(hexToHsl(response.data.organization.configurations.theme.on_primary_container));
						 this.protcolor = hslmaker(hexToHsl(response.data.organization.configurations.theme.on_secondary_container));
						 this.carbcolor = hslmaker(hexToHsl(response.data.organization.configurations.theme.on_tertiary_container));
						 this.fatcolor = hslmaker(hexToHsl(response.data.organization.configurations.theme.on_error_container));
						 
						 this.caloriecolorlight = hslbackligh(hexToHsl(response.data.organization.configurations.theme.on_primary_container));
						 this.protcolorlight = hslbackligh(hexToHsl(response.data.organization.configurations.theme.on_secondary_container));
						 this.carbcolorlight = hslbackligh(hexToHsl(response.data.organization.configurations.theme.on_tertiary_container));
						 this.fatcolorlight = hslbackligh(hexToHsl(response.data.organization.configurations.theme.on_error_container));
						// this.configService.setStorage ('lowN',this.lowNutrition);
						

						 





						 this.bglight = hexToHsl(response.data.organization.configurations.theme.primary);
						 this.bglight = bglightconvert(this.bglight);

						 this.bgdark = hexToHsl(response.data.organization.configurations.theme.on_primary);
						 this.bgdark = bgdarkconvert(this.bgdark);


						 this.highlight = hexToHsl(response.data.organization.configurations.theme.secondary);
						 this.highlight = highlightconvert(this.highlight);

						 this.highdark = hexToHsl(response.data.organization.configurations.theme.on_secondary);
						 this.highdark = highlightconvert(this.highdark);

						 this.diffuselight = hexToHsl(response.data.organization.configurations.theme.tertiary);
						 this.diffuselight = hsldiffuselight(this.diffuselight, 0.3);
						 this.diffusedark = hexToHsl(response.data.organization.configurations.theme.on_tertiary);
						 this.diffusedark = hsldiffusedark(this.diffusedark, 0.3);
						 this.diffusedarkselected = hexToHsl(response.data.organization.configurations.theme.on_tertiary);
						 this.diffuselightselected = hexToHsl(response.data.organization.configurations.theme.tertiary);
						 this.diffusedarkselected = hsldiffusedark(this.diffusedarkselected, 0.6);
						 this.diffuselightselected = hsldiffuselight(this.diffuselightselected, 0.6);



						 this.colorlight = hexToHsl(response.data.organization.configurations.theme.primary);
						 this.colorlight = hslcolorlight(this.colorlight);
						//  console.log(this.colorlight);
						//  this.colorlight = makecolor(this.colorlight);
						 
						 this.colordark = hexToHsl(response.data.organization.configurations.theme.on_primary);
						 this.colordark = hslcolordark(this.colordark);
						//  this.colordark = makecolor(this.colordark);

						this.colorback = hexToHsl(response.data.organization.configurations.theme.on_secondary);
						this.colorback = hslbackligh(this.colorback);
						// this.colorback = makecolor(this.colorback);
						this.colorbackdark = hexToHsl(response.data.organization.configurations.theme.on_secondary);
						this.colorbackdark = hslbackdark(this.colorbackdark);
						// this.colorbackdark = makecolor(this.colorbackdark);

						this.shadowhighlight = hexToHsl(response.data.organization.configurations.theme.secondary);
						this.shadowhighlight = hslshadowvar(this.shadowhighlight);
						this.shadowhighdark = hexToHsl(response.data.organization.configurations.theme.on_secondary);
						this.shadowhighdark = hslshadowvar(this.shadowhighdark);


						this.p3 = hextorgb(response.data.organization.configurations.theme.primary);
						this.p3 = convertopacity(this.p3,0.5);
						//console.log(this.prim);
						this.s3 = hextorgb(response.data.organization.configurations.theme.secondary);
						this.s3 = convertopacity(this.s3,0.5);
						this.t3 = hextorgb(response.data.organization.configurations.theme.tertiary);
						this.t3 = convertopacity(this.t3,0.5);

						htmlEl.style.setProperty('--p3', this.p3);
						htmlEl.style.setProperty('--s3', this.s3);
						htmlEl.style.setProperty('--t3', this.t3);

						htmlEl.style.setProperty('--low-nutri',this.lowNutrition); 
						htmlEl.style.setProperty('--avg-nutri', this.avgNutrition);
						htmlEl.style.setProperty('--high-nutri', this.highNutrition);
						





						if (this.settings.appDarkMode){
							console.log("Dark mode is enabled");


							htmlEl.style.setProperty('--ion-color-primary',this.p2);
							htmlEl.style.setProperty('--ion-color-secondary', this.s2);
							htmlEl.style.setProperty('--ion-color-tertiary', this.t2);
							htmlEl.style.setProperty('--p2',  this.colorprimary);
							htmlEl.style.setProperty('--s2', this.colorsecondary);
							htmlEl.style.setProperty('--t2', this.colortertiary);

							htmlEl.style.setProperty('--shadow-light', this.shadowdark); //grays only for base shadows

							htmlEl.style.setProperty('--shadow-color-light', this.shadowcolordark); //colored shadows will be based on primary color

							htmlEl.style.setProperty('--bg-light', this.bgdark); // background color will be based on primary color

							htmlEl.style.setProperty('--highlight', this.highdark); //highlights colors - based on secondary color
							htmlEl.style.setProperty('--shadowhighlight', this.shadowhighdark); //based on the highlight color

							htmlEl.style.setProperty('--diffuse-light', this.diffusedark); //based on tertiary color for glass based selected items effect.
							htmlEl.style.setProperty('--diffuse-dark', this.diffuselight);

							htmlEl.style.setProperty('--diffuse-light-selected', this.diffusedarkselected); //selected variant of the above. 
							htmlEl.style.setProperty('--diffuse-dark-selected', this.diffuselightselected);
							htmlEl.style.setProperty('--diffuse-selected', this.diffusedarkselected);
							htmlEl.style.setProperty('--diffuse', this.diffusedark);


							htmlEl.style.setProperty('--ion-color-light', this.colordark); //almost black primary
							htmlEl.style.setProperty('--ion-color-dark', this.colorlight); //almost white primary
							htmlEl.style.setProperty('--colorback',this.colorbackdark); //svg color for background

							htmlEl.style.setProperty('--kcal-light',  this.caloriecolor);
							htmlEl.style.setProperty('--prot-light', this.protcolor);
							htmlEl.style.setProperty('--carb-light', this.carbcolor);
							htmlEl.style.setProperty('--fat-light', this.fatcolor);
	
							htmlEl.style.setProperty('--prot', this.protcolorlight);
							htmlEl.style.setProperty('--carb', this.carbcolorlight);
							htmlEl.style.setProperty('--fat', this.fatcolorlight);
							htmlEl.style.setProperty('--kcal', this.caloriecolorlight);



					  } else {
						console.log("light mode is enabled");

							htmlEl.style.setProperty('--ion-color-primary', this.colorprimary);
							htmlEl.style.setProperty('--ion-color-secondary', this.colorsecondary);
							htmlEl.style.setProperty('--ion-color-tertiary', this.colortertiary);

							htmlEl.style.setProperty('--p2', this.colorprimary);
							htmlEl.style.setProperty('--s2', this.colorsecondary);
							htmlEl.style.setProperty('--t2', this.colortertiary);

							htmlEl.style.setProperty('--shadow-light', this.shadowlight);

							htmlEl.style.setProperty('--shadow-color-light', this.shadowcolorlight);

							htmlEl.style.setProperty('--bg-light', this.bglight);

							htmlEl.style.setProperty('--highlight', this.highlight);
							htmlEl.style.setProperty('--shadowhighlight', this.shadowhighlight);

							htmlEl.style.setProperty('--diffuse-light', this.diffuselight);
							htmlEl.style.setProperty('--diffuse-dark', this.diffusedark);

							htmlEl.style.setProperty('--diffuse-light-selected', this.diffuselightselected);
							htmlEl.style.setProperty('--diffuse-dark-selected', this.diffusedarkselected);
							htmlEl.style.setProperty('--diffuse-selected', this.diffusedarkselected);
							htmlEl.style.setProperty('--diffuse', this.diffusedark);

							htmlEl.style.setProperty('--ion-color-light', this.colorlight);
							htmlEl.style.setProperty('--ion-color-dark', this.colordark);
							htmlEl.style.setProperty('--colorback',this.colorlight);

							htmlEl.style.setProperty('--kcal',  this.caloriecolor);
							htmlEl.style.setProperty('--prot', this.protcolor);
							htmlEl.style.setProperty('--carb', this.carbcolor);
							htmlEl.style.setProperty('--fat', this.fatcolor);
	
							htmlEl.style.setProperty('--prot-light', this.protcolorlight);
							htmlEl.style.setProperty('--carb-light', this.carbcolorlight);
							htmlEl.style.setProperty('--fat-light', this.fatcolorlight);
							htmlEl.style.setProperty('--kcal-light', this.caloriecolorlight);

					  }

					 



					  
						console.log(response);
						this.configService.setStorage('appPayload', response.data.organization);
						this.events.publish('payload:configuration', { appPayload: response.data.organization, time: new Date() });
						const isDarkMode =  this.settings.appDarkMode;
						await this.checkLatestVersion(response.data.organization.force_update);
						this.backButtonEvent();
					}
				}
				);
		}, 100);	


		this.events.subscribe('language:change', (data: any) => {
			console.log(data)
			setTimeout(() => {
				window.location.reload();
			}, 5);
		});

				this.events.subscribe('DarkMode:change', (data: any) => {
			console.log(data);
			setTimeout(() => {
				const htmlEl = document.querySelector('html');

						console.log(data);
						

						if (data){
							console.log("Dark mode is enabled");


							htmlEl.style.setProperty('--ion-color-primary',this.p2);
							htmlEl.style.setProperty('--ion-color-secondary', this.s2);
							htmlEl.style.setProperty('--ion-color-tertiary', this.t2);
							htmlEl.style.setProperty('--p2',  this.colorprimary);
							htmlEl.style.setProperty('--s2', this.colorsecondary);
							htmlEl.style.setProperty('--t2', this.colortertiary);


							htmlEl.style.setProperty('--shadow-light', this.shadowdark);

							htmlEl.style.setProperty('--shadow-color-light', this.shadowcolordark);

							htmlEl.style.setProperty('--bg-light', this.bgdark);

							htmlEl.style.setProperty('--highlight', this.highdark);
							htmlEl.style.setProperty('--shadowhighlight', this.shadowhighdark);

							htmlEl.style.setProperty('--diffuse-light', this.diffusedark);
							htmlEl.style.setProperty('--diffuse-dark', this.diffuselight);

							htmlEl.style.setProperty('--diffuse-light-selected', this.diffusedarkselected);
							htmlEl.style.setProperty('--diffuse-dark-selected', this.diffuselightselected);

							htmlEl.style.setProperty('--ion-color-light', this.colordark);
							htmlEl.style.setProperty('--ion-color-dark', this.colorlight);
							htmlEl.style.setProperty('--colorback',this.colorbackdark);


							htmlEl.style.setProperty('--kcal-light',  this.caloriecolor);
							htmlEl.style.setProperty('--prot-light', this.protcolor);
							htmlEl.style.setProperty('--carb-light', this.carbcolor);
							htmlEl.style.setProperty('--fat-light', this.fatcolor);
	
							htmlEl.style.setProperty('--prot', this.protcolorlight);
							htmlEl.style.setProperty('--carb', this.carbcolorlight);
							htmlEl.style.setProperty('--fat', this.fatcolorlight);
							htmlEl.style.setProperty('--kcal', this.caloriecolorlight);


					


					  } else {
						console.log("light mode is enabled");

							htmlEl.style.setProperty('--ion-color-primary', this.colorprimary);
							htmlEl.style.setProperty('--ion-color-secondary', this.colorsecondary);
							htmlEl.style.setProperty('--ion-color-tertiary', this.colortertiary);

							htmlEl.style.setProperty('--p2', this.colorprimary);
							htmlEl.style.setProperty('--s2', this.colorsecondary);
							htmlEl.style.setProperty('--t2', this.colortertiary);

							htmlEl.style.setProperty('--shadow-light', this.shadowlight);

							htmlEl.style.setProperty('--shadow-color-light', this.shadowcolorlight);

							htmlEl.style.setProperty('--bg-light', this.bglight);

							htmlEl.style.setProperty('--highlight', this.highlight);
							htmlEl.style.setProperty('--shadowhighlight', this.shadowhighlight);

							htmlEl.style.setProperty('--diffuse-light', this.diffuselight);
							htmlEl.style.setProperty('--diffuse-dark', this.diffusedark);

							htmlEl.style.setProperty('--diffuse-light-selected', this.diffuselightselected);
							htmlEl.style.setProperty('--diffuse-dark-selected', this.diffusedarkselected);

							htmlEl.style.setProperty('--ion-color-light', this.colorlight);
							htmlEl.style.setProperty('--ion-color-dark', this.colordark);
							htmlEl.style.setProperty('--colorback',this.colorlight);

							htmlEl.style.setProperty('--kcal',  this.caloriecolor);
							htmlEl.style.setProperty('--prot', this.protcolor);
							htmlEl.style.setProperty('--carb', this.carbcolor);
							htmlEl.style.setProperty('--fat', this.fatcolor);
	
							htmlEl.style.setProperty('--prot-light', this.protcolorlight);
							htmlEl.style.setProperty('--carb-light', this.carbcolorlight);
							htmlEl.style.setProperty('--fat-light', this.fatcolorlight);
							htmlEl.style.setProperty('--kcal-light', this.caloriecolorlight);

					  }

			}, 50);
		});

		this.events.subscribe('user:login', (data: any) => {
			const customer = this.authService.getAuthUser();
			console.log(customer);
			console.log(data.user);
			if (data.user == null) {
				this.configService.getAppSettings()
					.then(async (settingData: any) => {
						this.settings = JSON.parse(settingData);
						console.log(this.settings);
						this.settings.currentUser = null;
						this.configService.setStorage('appSettings', this.settings);
						
						try {
							const element = await this.modalCtrl.getTop();
							if (element) {
								element.dismiss(); return;
							}
						} catch (error) {
							console.log(error);
						}
						this.authService.setAuthUser(null);
						this.authService.logOutUser();
						this.navCtrl.navigateRoot(['/onboarding']);
					}
					);
			} {
				
			}
		});
		this.events.subscribe('app:update', (data: any) => {
			const customer = this.authService.getAuthUser();
			console.log(customer);
			if (data.user == null) {
				this.configService.getAppSettings()
					.then(async (settingData: any) => {
						this.settings = JSON.parse(settingData);
						console.log(this.settings);
						this.settings.currentUser = null;
						this.configService.setStorage('appSettings', this.settings);
						this.authService.setAuthUser(null);
						this.authService.logOutUser();
						this.navCtrl.navigateRoot(['/force-update']);
					}
					);
			}
		});
	}


  ngOnInit() {
    // Preload the first set of dates on app startup
    // this.dataService.loadDateRange(this.currentDay, this.preloadWindow).subscribe();
	// this.dataService.initializeStorage();
	this.configService.removeStorage('weeklymenu');
	// this.dataService.triggerMenuLoadEvent(moment().format("YYYY-MM-DD"));
	
  }

	checkLatestVersion = async (appData: any) => {
		const versionNumbner = this.APP_VERSION; //await this.appVersion.getVersionNumber();
        if (this.platform.is("android")) {
			const androidResponse = appData.android;
			this.androidURL = androidResponse.application_url;
			this.serverVersion = androidResponse.latest_version.split('.');
			this.splittedVersion = versionNumbner.split('.');
			//alert(this.serverVersion[0] +' - '+ this.splittedVersion[0] + ' - '+androidResponse.enabled);
			if (androidResponse.enabled) {
				this.backButton = false;
				this.appUpdate(appData.alerts.title, appData.alerts.description);
			} else if (this.serverVersion[0] > this.splittedVersion[0]) {
				//major changes
				this.backButton = false;
				this.appUpdate(appData.alerts.title, appData.alerts.description, appData.alerts.button_text);
				this.events.publish('app:update', { user: null, time: new Date() });
			} else if (this.serverVersion[1] > this.splittedVersion[1]) {
				//minor changes
				this.appUpdate(appData.alerts.title, appData.alerts.minor.description, appData.alerts.button_text, true);
			} else if (this.serverVersion[2] > this.splittedVersion[2]) {
				//optional changes
				this.appUpdate(appData.alerts.title, appData.alerts.description, appData.alerts.button_text, true);
			}
		} else if (this.platform.is("ios")) {
			const iosResponse = appData.ios;
			//alert(versionNumbner);
			//alert(iosResponse.latest_version);
			this.iosURL = iosResponse.url;
			this.serverVersion = iosResponse.latest_version.split('.');
			this.splittedVersion = versionNumbner.split('.');
			if (iosResponse.enabled) {
				this.backButton = false;
				this.appUpdate(appData.alerts.title, appData.alerts.description);
			} else if (this.serverVersion[0] > this.splittedVersion[0]) {
				//major changes
				this.backButton = false;
				this.appUpdate(appData.alerts.title, appData.alerts.description, appData.alerts.button_text);
				this.events.publish('app:update', { user: null, time: new Date() });
			} else if (this.serverVersion[1] > this.splittedVersion[1]) {
				//minor changes
				this.appUpdate(appData.alerts.title, appData.alerts.description, appData.alerts.button_text, true);
			} else if (this.serverVersion[2] > this.splittedVersion[2]) {
				//optional changes
				this.appUpdate(appData.alerts.title, appData.alerts.description, appData.alerts.button_text, true);
			}
		}
	}
	openAppstoreEntry = async () => {
		if (this.platform.is("android")) {
			this.inAppBrowser.create(this.androidURL, '_blank');
			// NativeMarket.openStoreListing({
			//     appId: await this.appVersion.getPackageName().then(packageName => {return packageName;})
			// });
		} else if (this.platform.is("ios")) {
			this.inAppBrowser.create(this.iosURL, '_blank');
		}
	}
	appUpdate = async (header, message, buttonText = '', allowcloss = false) => {
		const buttons = [];
		if (buttonText != '') {
			buttons.push({
				text: buttonText,
				handler: () => {
					this.openAppstoreEntry();
				}
			});
		}
		if (allowcloss) {
			buttons.push({
				text: 'Close',
				role: 'Cancel'
			});
		}
		await this.alertCtrl.create({
			header,
			message,
			buttons,
			mode: 'ios',
			backdropDismiss: allowcloss
		}).then(alertElem => alertElem.present());

	}
	appUser = (currentUser: any) => {
		console.log(currentUser);
		if (currentUser && currentUser != null &&
			window.localStorage.getItem('auth-token') != 'null' &&
			window.localStorage.getItem('auth-token') != null) 
			{
				
		} else {
			window.localStorage.setItem('auth-token', null);
		}
	}
	backButtonEvent = () => {
		if (this.backButton) {
			this.platform.backButton
				.subscribeWithPriority(10, async () => {
					//try { const element = await this.alertCtrl.getTop(); if (element) { element.dismiss(); return; } } catch (error) { console.log(error); }
					try { const element = await this.modalCtrl.getTop(); if (element) { element.dismiss(); return; } } catch (error) { console.log(error); }
					this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
						//alert(this.router.url);
						if (outlet && outlet.canGoBack()) {
							outlet.pop();
						} else if (this.router.url === '/'
							|| this.router.url === '/login'
							|| this.router.url === '/signin'
							|| this.router.url === '/tabs/menu'
							|| this.router.url === '/tabs/calendar'
							|| this.router.url === '/tabs/notifications'
							|| this.router.url === '/tabs/accounts'
							|| this.router.url === '/tabs/order'
						) {
							if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
								await this.showConfirm();
							} else {
								await this.toastCtrl.create({
									message: this.translate.instant('press_back_again_to_exit_app'),
									duration: 4000,
									position: 'top',
									color: 'light'
								}).then((toastElem) => toastElem.present());

							}
							this.lastTimeBackPress = new Date().getTime();
						}
						// else if(this.router.url === '/subscription-list?historyRoute=login&landingPage=signup')
						// {
						//     alert('MD');
						// }
					});
				});
		}
	}
	checkNetworkConnection = () => {
		this.network.onDisconnect()
			.subscribe(() => {
				this.connectedToInternet = false;
				this.translate.get(["Please Connect to the Internet!", "Disconnected"])
					.subscribe(async (res) => {
						let alertMsg = await this.alertCtrl.create({
							header: this.translate.instant('no_internet'),
							message: this.translate.instant('please_connect_to_the_internet'),
							mode: 'ios',
							buttons: [
								{
									text: this.translate.instant('ok'),
									handler: () => {
										navigator['app'].exitApp();
									}
								}
							]
						});
						alertMsg.present();
					}
					);
			}
			);
		this.network.onConnect()
			.subscribe(() => {
				if (!this.connectedToInternet) {
					window.location.reload();
					this.translate.get(["Network connected Reloading Data", "Connected"])
						.subscribe(async (res) => {
							let alertMsg = await this.alertCtrl.create({
								header: this.translate.instant('network_found'),
								message: this.translate.instant('network_connected_reloading_data'),
								mode: 'ios',
								buttons: [{ text: this.translate.instant('ok') }]
							});
							alertMsg.present();
						}
						);
				}
			}
			);
	}
	showConfirm = async () => {
		const alert = await this.alertCtrl.create({
			header: this.translate.instant('confirm'),
			message: this.translate.instant('are_you_sure_you_want_to_exit_the_app'),
			buttons: [
				{
					text: this.translate.instant('cancel'),
					role: 'cancel',
					cssClass: 'secondary',
					handler: (blah) => { }
				},
				{
					text: this.translate.instant('close_app'),
					handler: () => {
						navigator['app'].exitApp();
					}
				}],
			//enterAnimation: customAlertEnter
		});
		await alert.present();
	}
}
